import { Action, ActionReducer, createReducer, on } from "@ngrx/store";
import { OrderConfirmationValidator } from "helpers/order-confirmation-validator";
import * as OrderConfirmationActions from 'store/order-confirmation/order-confirmation.actions';
import { OrderConfirmationState } from "./order-confirmation.state";

export const initialState: OrderConfirmationState = {
  order: null,
  partBinLocations: {},
  createBillingEnabled: false,
  createBillingShown: false,
  readyToPickShown: true,
  readyToPickEnabled: true,
  selectedPartBinLocations: [],
  orderConfirmationTotal: {
    cartCouponTotal: 0,
    couponTotal: 0,
    estimatedFreight: 0,
    estimatedDelivery: 0,
    deliveryFee: 0,
    deliveryFeeCredit: 0,
    taxAmount: 0,
    subTotal: 0,
    total: 0
  }
};

const _orderConfirmationReducer = createReducer<OrderConfirmationState, Action>(
  initialState,
  on(
    OrderConfirmationActions.setPartBinLocations,
    (state, {partBins}) => {
      return ({...state, partBinLocations: partBins });
    }
  ),
  on(OrderConfirmationActions.setOrder,
    (state, {order}) => {

      //Validation Rules For RTP and Create Billing buttons
      const orderItems: { job: string; deliveryOption: string, partNumber: string }[] = order.orderItems.filter(x => x.partNumber != "DELIVERY:90" && x.partNumber != "FREIGHT").map(x => ({job: x.jobNumber, deliveryOption: x.deliveryOption, partNumber: x.partNumber}));
      const ocValidator = new OrderConfirmationValidator({orderItems, orderType: order.order.orderType});
      const {createBillingEnabled, readyToPickShown, showCreateBillingButton} = ocValidator.validate();
      return ({...state, order, createBillingShown: showCreateBillingButton, readyToPickShown, createBillingEnabled});
    }),
  on(OrderConfirmationActions.clearOrderConfirmationData,
    (state) => ({...initialState})),
  on(OrderConfirmationActions.selectPartBinLocations,
    (state, {partBins}) => ({...state, selectedPartBinLocations: partBins})),
  on(OrderConfirmationActions.readyToPickSuccess,
    (state) => ({ ...state, createBillingEnabled: !state.createBillingEnabled, readyToPickEnabled: false })),
  on(OrderConfirmationActions.setCreateBillingVisible,
    (state, { createBillingEnabled }) => ({ ...state, createBillingEnabled: createBillingEnabled })),
  on(OrderConfirmationActions.setCreateBillingEnabled,
    (state, { createBillingEnabled }) => ({ ...state, createBillingEnabled: createBillingEnabled })),
  on(OrderConfirmationActions.setOrderConfirmationTotal,
    (state, { orderConfirmationTotal }) => ({ ...state, orderConfirmationTotal }))
);

function persistStateReducer(key: string, _reducer: ActionReducer<OrderConfirmationState, Action>) {
  return (state: OrderConfirmationState | undefined, action: Action) => {
    const nextState = _reducer(state, action);
    // Save data to session storage if necessary
    return nextState;
  };
}

export function orderConfirmationReducer(state: OrderConfirmationState | undefined, action: Action) {
  return persistStateReducer('OrderConfirmationState', _orderConfirmationReducer)(state, action);
}
