import { Action, createReducer, on } from '@ngrx/store';
import { CartResult as CartDetails, CartResult } from 'entities/cart-result';
import { DeliveryItems } from 'entities/delivery-items';
import ApplyDiscounts from 'helpers/apply-discounts';
import * as BasketActions from 'store/basket/basket.actions';
import * as ConfigurationActions from 'store/configuration/configuration.actions';
import * as FeaturedPartsActions from 'store/featured-parts/featured-parts.actions';
import * as LineItemNotesModalComponentActions from '../../_modals/line-item-notes-modal/line-item-notes-modal.component.actions';
import * as CustomerActions from '../customer/customer.actions';
import * as CartActions from './cart.actions';

export const initialState: CartDetails = {
  cartCoupons: [],
  cartId: null,
  poNumber: null,
  unitNumber: null,
  billingType: null,
  billToAddressNo: null,
  deliveryType: null,
  hideFinalPitch: true,
  lineItems: [],
  loyaltyAccounts: {peterbilt: null},
  prop65: true,
  smsContactName: null,
  smsMobileNumber: null,
  smsOptIn: false,
  specialInstruction: null,
  alternateEmail: null,
  transactionType: null,
  orderChannel: null,
  totalCarts: null,
  isLocked: false,
  hasUnsavedChanges: false,
  isLoading: false,
  isShippingRatesLoading: false,
  shippingRates: [],
  shippingRate: null,
  vin: null,
  freight: null,
  taxes: null,
  isSubmitLocked: false,
  deliveryItems: DeliveryItems.all
} as CartDetails;

export const _cartReducer = createReducer<CartDetails, Action>(
  initialState,
  on(ConfigurationActions.loadCacheSuccess, (state, {appstate}) => {
    const deliveryItems = state.deliveryItems;
    const selectedCart = {...appstate.selectedCart};
    selectedCart.deliveryItems = deliveryItems;
    return {...selectedCart}
  }),
  on(FeaturedPartsActions.hideFinalPitchSuccess, (state) => ({...state, hideFinalPitch: true})),
  on(CartActions.loadCachedStateSuccess, (state, {cartState}) => ({...cartState, isShippingRatesLoading: false})),
  on(CartActions.getCartDataSuccess, CartActions.loadCartForQuoteSuccess, ((state, {cartResult}) => (cartResult ? {...cartResult} : initialState))),
  on(CartActions.priceOverrideSuccess, (state, {cart}) => ({...cart})),
  on(CartActions.getCartDataFailed, ((state) =>
    ({...state, isLoading: false})
  )),
  on(CartActions.setDeliverySuccess, ((state, {cart}) =>
    ({...cart, orderChannel: state.orderChannel, transactionType: state.transactionType, shippingRates: state.shippingRates, shippingRate: state.shippingRate })
  )),
  on(CartActions.addItemToCartSuccess, CartActions.updateCartItemSuccess, ((state, {cart}) =>
    ({ ...cart })
  )),
  on(BasketActions.addBasketToCartSuccess, ((state, {cart}) =>
    ({ ...cart })
  )),
  on(CartActions.updateSpecialInstructionSuccess, ((state, { cart }) =>
    ({...cart, orderChannel: state.orderChannel, transactionType: state.transactionType })
  )),
  on(CartActions.updateAlternateEmailSuccess, ((state, { cart }) =>
    ({...cart, orderChannel: state.orderChannel, transactionType: state.transactionType, shippingRates: state.shippingRates })
  )),
  on(CartActions.updateSmsOptSuccess, ((state, { cart }) =>
      ({ ...cart, orderChannel: state.orderChannel, transactionType: state.transactionType })
  )),
  on(CartActions.getTaxesSuccess, ((state, { taxes }) =>
      ({ ...state, taxes })
  )),
  on(CartActions.addPartToFavoritesSuccess, CartActions.removePartFromFavoritesSuccess, ((state, {partId}) =>
      ({...state, lineItems: [...state.lineItems.map(i => i.partId === partId ? {...i, isFavoritePart: !i.isFavoritePart} : i)] })
  )),
  on(
    CartActions.submitOrderSuccess,
    CartActions.submitQuoteSuccess,
    CartActions.clearCartData,
    (state) => ({...initialState})),
  on(CartActions.getPartBinLocationsSuccess, (state, {partBinLocations}) =>
    {
      const lineItems = state.lineItems?.map(item => {
        const partNumber = item.partNumber.toLowerCase();
        const hasPartsInNonPickableBins = partBinLocations[item.partNumber.toLocaleLowerCase()]?.reduce((prev, curr) => prev + curr.quantityAvailable, 0) <= 0;
        return {...item, availableBinLocations: partBinLocations[partNumber], hasPartsInNonPickableBins};
      });
      return ({...state, lineItems})
    }
  ),
  on(CartActions.updatePONumberSuccess, ((state, {cart}) =>
    ({...cart, orderChannel: state.orderChannel, transactionType: state.transactionType, shippingRates: state.shippingRates})
  )),
  on(CartActions.updateUnitNumberSuccess, ((state, {cart}) =>
    ({...cart, orderChannel: state.orderChannel, transactionType: state.transactionType, shippingRates: state.shippingRates})
  )),
  on(
    CartActions.updateBillToSuccess, ((state, { cart }) =>
    ({...cart, orderChannel: state.orderChannel, transactionType: state.transactionType, shippingRates: state.shippingRates })
  )),
  on(CartActions.setDeliveryFeeSuccess, ((state, {cart}) =>
    ({...cart, taxes: state.taxes, shippingRates: state.shippingRates, orderChannel: state.orderChannel, transactionType: state.transactionType})
  )),
  on(CartActions.setFreightFeeSuccess, ((state, {cart}) =>
  ({...cart, taxes: state.taxes, orderChannel: state.orderChannel, transactionType: state.transactionType, shippingRates: state.shippingRates})
  )),
  on(CartActions.updateShipToSuccess, ((state, {cart, isCartUpdate }) =>
    ({...cart, orderChannel: state.orderChannel, transactionType: state.transactionType, shippingRates: state.shippingRates, hasUnsavedChanges: isCartUpdate})
  )),
  on(CartActions.setInboundFreight, ((state, {inboundFreightLineItem}) =>
    ({...state, lineItems: [...state.lineItems.filter(x => x.partNumber !== "FREIGHT"), inboundFreightLineItem]})
  )),
  on(CartActions.getShippingRates, ((state, {cartId}) =>
    ({...state, shippingRate: state.shippingRate, isShippingRatesLoading: true })
  )),
  on(CartActions.getShippingRatesSuccess, ((state, {shippingRates}) =>
    ({...state, shippingRates, isShippingRatesLoading: false})
  )),
  on(CartActions.getShippingRatesFailed, ((state, {error}) =>
    ({...state, isShippingRatesLoading: false})
  )),
  on(CartActions.updateOrderChannel, ((state, {orderChannel}) =>
    ({...state, orderChannel})
  )),
  on(CartActions.confirmPartsBuyoutSuccess, (state, { cart }) => ({ ...cart})),
  // TODO: check these two delete/remove, can there be only one for all cases. It should be
  on(CartActions.deleteCartItemSuccess, ((state, {cartLineItemId}) =>
    ({...state, lineItems: [...state.lineItems.filter(li => li.cartItemId != cartLineItemId)] })
  )),
  on(CartActions.removeItemsFromCartSuccess, CartActions.closeQuoteItemsSuccess, ((state, { cart }) => ({ ...cart }))),
  on(CartActions.addStoItemToCartSuccess,
    CartActions.addStoItemFromNationalInventorySuccess,
    CartActions.addItemToCartFromPartsBuyoutSuccess,
    CartActions.addFavoritePartsToCartSuccess,
    ((state, { cart }) => ({ ...cart })
  )),
  on(CartActions.unlockQuoteForOthersSuccess, (state =>
    ({...state, isLocked: false})
  )),
  on(CartActions.selectCartSuccess, ((state, {cart}) =>
    ({...cart, deliveryItems: state.deliveryItems})
  )),
  on(CartActions.updateHotFlagOnCartItemSuccess, ((state, {cart}) =>
    ({...cart, shippingRates: state.shippingRates, shippingRate: state.shippingRate, taxes: state.taxes})
  )),
  on(CartActions.deleteCartSuccess, (state, { cartId }) => {
    if(state.cartId === cartId){
      return ({...initialState});
    }
    return ({...state});
  }),
  on(CartActions.updateQuoteSuccess, ((state, { cart }) =>
    ({...cart})
  )),
  on(CartActions.applyLoyaltyDiscounts, (state, { discounts }) => {
    return ({...state, lineItems: ApplyDiscounts(state.lineItems, discounts)});
  }),
  on(
    LineItemNotesModalComponentActions.addedItemNote,
    (state, { itemId }) => ({
      ...state,
      lineItems: state.lineItems
        .map((item) => ({
          ...item,
          cartItemNotesCount: item.cartItemId === itemId
            ? item.cartItemNotesCount + 1
            : item.cartItemNotesCount
        }))
    })
  ),
  on(
    LineItemNotesModalComponentActions.removedItemNote,
    (state, { itemId }) => ({
      ...state,
      lineItems: state.lineItems
      .map((item) => ({
        ...item,
        cartItemNotesCount: item.cartItemId === itemId
          ? item.cartItemNotesCount - 1
          : item.cartItemNotesCount
      }))
    })
  ),
  on(CartActions.priceVerifySuccess, (state, {cart}) => ({...cart})),
  on(
    BasketActions.addBasketItemsToCart,
    CartActions.setDelivery,
    CartActions.addItemToCart, (state) => ({...state, isLoading: true})),
  on(
     BasketActions.addBasketToCartSuccess,
     BasketActions.addBasketItemsToCart,
     CartActions.addItemToCartSuccess,
     CartActions.setDeliverySuccess,
     CartActions.addItemToCartFailed, (state) => ({...state, isLoading: false})),
  on(CustomerActions.setSelectedPaymentMethodSuccess, (state, {cart}) => ({...cart, transactionType: state.transactionType,
     orderChannel: state.orderChannel, shippingRates: state.shippingRates })),
  on(CartActions.updateTransactionType, (state, {orderType}) => ({...state, transactionType: orderType })),
  on(CartActions.updateVIN, (state, {vin}) => {
    return ({...state, vin});
  }),
  on(CartActions.updateCartSuccess, (state, {cart, deliveryItems}) => {
    let shippingRates = [];
    let shippingRate = null;
    if(cart.deliveryType === 'shipto' || cart.lineItems.find(f => f.deliveryOption === 'C')){
      shippingRates = state.shippingRates;
      shippingRate = state.shippingRate
    }
    return ({...cart, taxes: state.taxes, isShippingRatesLoading: state.isShippingRatesLoading, shippingRates, shippingRate, deliveryItems});
  }),
  on(CartActions.updateShippingRateSuccess, (state, { shippingRate }) => ({...state, shippingRate})),
  on(CartActions.selectOpenCartSuccess, (state, data) => ({...state, customerName: data.cartResult?.customerName, customerNumber: data.cartResult?.customerNumber})),
  on(CartActions.setSubmitLock, (state, { isSubmitLocked }) => ({ ...state, isSubmitLocked })),
  on(CartActions.submitOrderFailed,
     CartActions.updateQuoteFailed, (state) => ({ ...state, isSubmitLocked: false })),
  on(CartActions.setDeliveryItems, (state, {deliveryItems}) => ({...state, deliveryItems}))
);

export function cartReducer(state: CartResult | undefined, action: Action) {
  return _cartReducer(state, action);
}
