import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { APPLICATION_NAME } from 'app/app.constants';
import { AppInsightBilling } from 'entities/app-insight-billing';
import { Branch } from 'entities/branch';
import { DeliveryType } from 'entities/cart-result';
import { ChipClipLineItems } from 'entities/chip-clip-line-item';
import { CreateBillingRequest, CreateBillingRequestLineItem } from 'entities/create-billing-request';
import { CurrencyCode } from 'entities/currency-code';
import { Permission, Roles } from 'entities/enums';
import { BagQuantity } from 'entities/order-confirmation/bag-quantity';
import { ProcurementGroup } from 'entities/procurement-group';
import { OrderConfirmation } from 'entities/purchase-order/order-confirmation';
import { OrderConfirmationItem } from 'entities/purchase-order/order-confirmation-item';
import { OrderConfirmationResult } from 'entities/purchase-order/order-confirmation-result';
import { SelectedPartBinLocation } from 'entities/selected-part-bin-location';
import { ToastType } from 'entities/toast-type';
import { isColoradoBranch } from 'helpers/colorado-branch-codes';
import { ChipClipInputModalComponent } from 'modals/chip-clip-input-modal/chip-clip-input-modal.component';
import { CustomerNotesModalComponent } from 'modals/customer-notes-modal/customer-notes-modal.component';
import { PurchaseOrderCreateModalComponent } from 'modals/purchase-order-create-modal/purchase-order-create-modal.component';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { catchError, first, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { AuthorizationService } from 'services/authorization.service';
import { BranchService } from 'services/branch.service';
import { CartService } from 'services/cart.service';
import { CommonDataService } from 'services/common-data.service';
import { DownloadService } from 'services/download.service';
import { FeatureFlagService } from 'services/feature-flag.service';
import { LoaderService } from 'services/loader.service';
import { LoggerService } from 'services/logger.service';
import { OrderService } from 'services/order.service';
import { QuoteService } from 'services/quote.service';
import { ToastService } from 'services/toast.service';
import { AppState } from 'store/app-state';
import * as BranchActions from "store/branch/branch.actions";
import * as BranchSelectors from "store/branch/branch.selectors";
import * as CartActions from "store/cart/cart.actions";
import * as CartSelectors from 'store/cart/cart.selectors';
import * as ConfigurationSelectors from 'store/configuration/configuration.selectors';
import * as FeatureFlagSelectors from 'store/feature-flags/feature-flags.selectors';
import * as OrderConfirmationActions from 'store/order-confirmation/order-confirmation.actions';
import * as OrderConfirmationSelectors from 'store/order-confirmation/order-confirmation.selectors';
import { OrderConfirmationTotal } from 'store/order-confirmation/order-confirmation.state';
import { CreatePurchaseOrdersBatchResult } from '../../_entities/purchase-order';
import { CheckoutBagQuantityComponent } from '../../_modals/checkout-count-bags-modal/checkout-bag-quantity-modal.component';
import { AuthenticationService } from '../../_modules/authentication/authentication.service';
import { DeliveryOptions } from '../checkout-line-item-delivery/checkout-line-item-delivery.component.state';

@Component({
  selector: 'order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.css']
})
export class OrderConfirmationComponent implements OnInit, OnDestroy {

  branch$: Observable<Branch>;
  permission: any = Permission;
  partsBuyOutData: OrderConfirmationItem[];
  hasUnknownPartsBuyOut: boolean;
  hotFlagData: OrderConfirmationItem[];
  hotFlag95And99Data: OrderConfirmationItem[];
  stockTransferData: OrderConfirmationItem[];
  cartCount: number;
  cartSubTotalAmount: any;
  mycartCount: any;
  errorMessage: any;
  deliveryOption: string = '';
  specialInstructions: string = '';
  selectedDelivery: string = '';
  cartMessage: string;
  notHotFlag95And99: boolean;
  orderItems: OrderConfirmationItem[] = null;
  orderItemCouponValues: { [id: string]: number } = {};
  includesCouponData: boolean = false;
  order: OrderConfirmation = null;
  showFreightRow: boolean = false;
  showDeliveryRow: boolean = false;
  printPickTicketFlag: boolean = false;
  printPOFlag: boolean = false;
  orderId: string = '';
  orderConfirmationMessage: string = '';
  createBillingFlag: boolean = false;
  createBillingErrorMsgs: any;
  authorizationNumber: string;
  completeOrderItems: OrderConfirmationItem[] = null;
  includePO: boolean = true;
  orderType: string = '';
  subTotal: number = 0;
  getQueryParametersSub: Subscription;
  includeCoreTags: boolean;
  hasCoreTags: boolean;
  billingOverrideModalRef: any;
  showRTPButton: boolean = false;
  showWMCreateBillingButton: boolean = false;
  isCustomerNotesFeatureEnabled: boolean = false;
  isCODeliveryAndBagFeeFeatureEnabled: boolean = false;
  uniqueDeliveryItems: string[] = null;
  isRTPDisabled: boolean = false;
  isShowCreatePOButton: boolean = true;
  isShowHotFlagCreatePOButton: boolean = false;
  billableDeliveryOptions: string[] = null;
  bagQuantity: BagQuantity;
  public createBillingButtonClicked: boolean = false;
  public overrideBins$: Observable<SelectedPartBinLocation[]> = this.store.select(OrderConfirmationSelectors.selectSelectedOverrideBins);
  private unsubscribeSubject = new Subject<void>();
  private isWarehouseManaged: boolean;
  private printContent: ElementRef;
  public chipClipLineItems: ChipClipLineItems[] = [];
  public procurementGroups$: Observable<ProcurementGroup[]> = new Observable<ProcurementGroup[]>;
  public isCODeliveryAndBagFee$: Observable<boolean> = this.store.select(FeatureFlagSelectors.isFeatureActive('CODeliveryAndBagFee'));

  @ViewChild('printsection') set content(content: ElementRef) {
    this.printContent = content;
  }
  @ViewChild('createBillingModal', { static: true }) createBillingModal: ElementRef;

  createBillingEnabled$: Observable<boolean> = this.store.select(OrderConfirmationSelectors.selectCreateBillingEnabled);
  createBillingShown$: Observable<boolean> = this.store.select(OrderConfirmationSelectors.selectCreateBillingShown);
  readyToPickEnabled$: Observable<boolean> = this.store.select(OrderConfirmationSelectors.selectReadyToPickEnabled);
  readyToPickShown$: Observable<boolean> = this.store.select(OrderConfirmationSelectors.selectReadyToPickShown);
  public userProfile$: Observable<{ unique_name?: string }> = this.authenticationService.userProfile$;
  showCreatePOButton: boolean;

  isInternal$: Observable<boolean> = this.store.select(ConfigurationSelectors.isInternal);
  printPickTicket$: Observable<boolean> = this.store.select(ConfigurationSelectors.hasPermission(Permission.PrintPickTicket));
  readSAPOrder$: Observable<boolean> = this.store.select(ConfigurationSelectors.hasPermission(Permission.ReadSAPOrder));
  createPurchaseOrder$: Observable<boolean> = this.store.select(ConfigurationSelectors.hasPermission(Permission.CreatePurchaseOrder));
  canCreateBilling$: Observable<boolean> = this.store.select(ConfigurationSelectors.hasPermission(Permission.CreateBilling));
  public currencyCode$: Observable<CurrencyCode> = this.store.select(BranchSelectors.selectCurrencyCode);
  orderConfirmationTotal$: Observable<OrderConfirmationTotal> = this.store.select(OrderConfirmationSelectors.selectOrderConfirmationTotal);

  constructor(private loader: LoaderService,
    private activatedRoute: ActivatedRoute,
    private branchService: BranchService,
    private cartService: CartService,
    private quoteService: QuoteService,
    private title: Title,
    @Inject(APPLICATION_NAME) private appName: string,
    private router: Router,
    public commonDataService: CommonDataService,
    private toastService: ToastService,
    private loggerService: LoggerService,
    public authorizationService: AuthorizationService,
    private modalService: NgbModal,
    private downloadService: DownloadService,
    private store: Store<AppState>,
    featureFlagService: FeatureFlagService,
    private orderService: OrderService,
    private authenticationService: AuthenticationService
  ) {
    this.title.setTitle(this.appName);
    this.includeCoreTags = false;
    this.hasCoreTags = false;
    this.isCustomerNotesFeatureEnabled = featureFlagService.isFeatureActive("FeatureFlag.CustomerNotes.Enabled");
    this.isCODeliveryAndBagFeeFeatureEnabled = featureFlagService.isFeatureActive("CODeliveryAndBagFee");
  }

 ngOnInit() {

    this.getQueryParametersSub = this.activatedRoute
     .queryParams
     .pipe(
      withLatestFrom(this.store.select(CartSelectors.selectIsQuote), this.isInternal$, this.userProfile$),
       tap(([params]) => {
         this.orderId = params['orderId'];
         this.orderType = params['orderType'];
         this.title.setTitle(`${this.appName} - ${this.orderType !== 'Quote' ? 'Order' : 'Quote'} Confirmation`);
         this.loggerService.verbose('Order confirmation OrderId : ', this.orderId);
         this.loader.loading = true;
       }),
       switchMap(
         ([_, isQuote, isInternal, userProfile]) => this.orderService.getOrderConfirmation(this.orderId).pipe(
          switchMap(oc => of({oc, isQuote, isInternal, userProfile})),
          catchError(error => of(error)),
          tap(() => this.loader.loading = false)
         )
       )
     ).subscribe({
       next: ({oc, isQuote, isInternal, userProfile}: {oc: OrderConfirmationResult, isQuote: boolean, isInternal: boolean, userProfile: {unique_name?: string}}) => {
         this.showCreatePOButton = oc?.orderItems.some(x => {
           return x.jobNumber === "000010";
         });

         if (!oc?.order) {
           this.orderItems = null;
           this.cartMessage = 'cart item is not available.';
         } else {
           const items = oc.orderItems?.map(x => {
             return {
               partNumber: x.partNumber,
               itemNum: x.itemNumber,
               orderNum: oc.order.sapOrderNo,
               quantity: x.quantity
             };
           });
           this.store.dispatch(OrderConfirmationActions.setOrder({ order: oc }));
           this.store.dispatch(OrderConfirmationActions.getPartBinLocations({ items }));
           this.title.setTitle(`${this.appName} - ${oc.order.orderType === 'SalesOrder' ? 'Order' : 'Quote'} Confirmation`);
           this.order = oc.order;
           this.loggerService.verbose(this.order);
           this.completeOrderItems = oc.orderItems;
           const orderItems: OrderConfirmationItem[] = oc.orderItems;
           this.branch$ = this.branchService.loadBranch(oc.order.branchCode)
             .pipe(
               first(),
               tap((branch) => this.isWarehouseManaged = branch.isWarehouseManaged)
             );

           this.uniqueDeliveryItems = orderItems
             .map((orderItem) => orderItem.deliveryOption)
             .filter((deliveryOption, i, arr) => arr.indexOf(deliveryOption) === i && deliveryOption);

           this.billableDeliveryOptions = this.uniqueDeliveryItems.filter(
             x => x === 'A' || x === 'L');

           const freight = orderItems
             .filter((orderItem) => orderItem.isFreight);

           if (this.order.hasBeenRTP) {
             this.isRTPDisabled = true;
           }

           let estimatedFreight = 0
           if (freight && freight.length > 0) {
             estimatedFreight = freight[0].finalPrice;
             this.showFreightRow = true;
           } else {
             this.showFreightRow = true;
           }

           const delivery = orderItems
             .filter((orderItem) =>
               orderItem.isCustomerDelivery
               || orderItem.isRTCDelivery
               || (orderItem.partNumOnly === 'DELIVERY:90' && !orderItem.isFreight && !orderItem.isCustomerDelivery && !orderItem.isRTCDelivery)
             );


             let estimatedDelivery = delivery?.length > 0 ? delivery[0].finalPrice : 0;

           const deliveryFeeExists = orderItems.find(x => x.partNumber === 'DELIVERY:90');
           if ((estimatedDelivery === 0 && this.commonDataService.User?.isInternal) ||
                  deliveryFeeExists && (
                    estimatedDelivery > 0 ||
                    this.uniqueDeliveryItems.some((x) => x === 'C' || x === 'D')
                  )
              ) {
             this.showDeliveryRow = true;
           }

           this.orderItems = orderItems
             .filter((orderItem) =>
               !orderItem.isBuyOut
               && !orderItem.isHotFlag
               && !orderItem.isSTO
               && !orderItem.isFreight
               && !orderItem.isCustomerDelivery
               && !orderItem.isRTCDelivery
               && !(orderItem.partNumOnly === 'DELIVERY:90' && !orderItem.isFreight && !orderItem.isCustomerDelivery && !orderItem.isRTCDelivery)
               && !orderItem.couponAssociatedId
             );

           this.partsBuyOutData = orderItems
             .filter((orderItem) => orderItem.isBuyOut);

           this.hasUnknownPartsBuyOut = orderItems
             .some((orderItem) => orderItem.partNumber === 'PARTSBUYOUTTX');

           this.hotFlagData = orderItems
             .filter((orderItem) => orderItem.isHotFlag);

          // Query for hotflag 95/99 only because we only create PO with 95 and 99 hotflags
          this.hotFlag95And99Data = orderItems
             .filter((orderItem) => orderItem.isHotFlag && (orderItem.hotFlagCode == '95' || orderItem.hotFlagCode == '99'));

            this.notHotFlag95And99 = orderItems
              .some((orderItem) => orderItem.isHotFlag && (orderItem.hotFlagCode != '95' && orderItem.hotFlagCode != '99'));

           this.stockTransferData = orderItems
             .filter((orderItem) => orderItem.isSTO);

           if (this.hotFlag95And99Data.length > 0 && this.partsBuyOutData.length === 0 && this.stockTransferData.length === 0) {
             this.isShowCreatePOButton = false;
             this.isShowHotFlagCreatePOButton = true;
           }

           if (this.hotFlag95And99Data.length > 0 || this.partsBuyOutData.length > 0 || this.stockTransferData.length > 0) {
            this.procurementGroups$ = this.orderService.getProcurementGroups(this.order.sapOrderNo);
          }

           this.hasCoreTags = orderItems
             .some((orderItem) => orderItem.coreOption === "CORE1");

           this.cartCount = this.orderItems.length + this.partsBuyOutData.length + this.hotFlagData.length + this.stockTransferData.length;

           this.deliveryOption = this.getOrderDeliveryType(this.order);

           this.orderItemCouponValues = orderItems
             .filter(oi => oi.couponAssociatedId)
             .reduce<{ [id: string]: number }>((acc, coupon) => {
               const couponValue = coupon.finalPrice * coupon.quantity;
               acc[coupon.couponAssociatedId] = (acc[coupon.couponAssociatedId] || 0) + couponValue;
               return acc;
             }, {});
           const coupons = orderItems.filter(oi => oi.couponAssociatedId);
           if (coupons.length) {
             this.includesCouponData = true;
           } else {
             const couponTotal = orderItems.filter((oi) => oi.couponPartTotal !== 0);
             if (couponTotal.length) {
               this.includesCouponData = true;

               this.orderItemCouponValues = orderItems
                 .filter((oi) => oi.couponPartTotal !== 0)
                 .reduce<{ [id: string]: number }>((acc, item) => {
                   const couponValue = item.couponPartTotal;
                   acc[item.id] = (acc[item.id] || 0) + couponValue;
                   return acc;
                 }, {});
             }
           }

           const subTotal = orderItems
             .filter((orderItem) => !orderItem.isFreight
               && !orderItem.isCustomerDelivery
               && !orderItem.isRTCDelivery
               && orderItem.partNumOnly !== 'DELIVERY:90')
             .reduce((total, orderItem) => total + this.calculateOrderItemValue(orderItem), 0);

           const partCouponTotal = orderItems
             .filter((orderItem) => orderItem.couponPartTotal !== 0)
             .reduce((total, orderItem) => total + orderItem.couponPartTotal, 0);

           const cartCouponTotal = oc.order?.cartCoupontotal || 0;
           const couponTotal = cartCouponTotal + partCouponTotal;
           let deliveryFee = 0;
           let deliveryFeeCredit = 0;
           if(this.isCODeliveryAndBagFeeFeatureEnabled) {
            deliveryFee = this.order?.deliveryFee || 0;
            deliveryFeeCredit = this.order?.deliveryFeeCredit || 0;
           }
           const taxAmount = this.order?.tax || 0;
           const orderConfirmationTotal: OrderConfirmationTotal = {
            cartCouponTotal,
            couponTotal,
            deliveryFee,
            deliveryFeeCredit,
            taxAmount,
            subTotal,
            estimatedFreight,
            estimatedDelivery,
            total: subTotal + estimatedFreight + estimatedDelivery + taxAmount + cartCouponTotal + deliveryFee + deliveryFeeCredit
           };
           this.store.dispatch(OrderConfirmationActions.setOrderConfirmationTotal({orderConfirmationTotal}));

           // Don't check for non-zero job number in Buyout, Hot Flag, STO, Delivery, Coupons
           const hasNonZeroJobNumber = this.orderItems
             .some((orderItem) => orderItem.jobNumber !== '000000');

           if (hasNonZeroJobNumber && this.order.orderType === 'SalesOrder' && isQuote) {
             this.toastService.showToast(`Please perform procurement and billing in the SAP system.`, ToastType.Info);
           }
           this.orderItems.filter(orderItem => {
             const isZser = orderItem.partNumber.indexOf(':90') > 0;
             return !isZser;
           }).forEach(item => {
             this.showWMCreateBillingButton = true;
             const jobNo: number = Number(item.jobNumber);
             if (jobNo === 1) {
               this.showRTPButton = true;
               this.createBillingFlag = true;
             }
           });
         }

        if (this.showRTPButton) {
           this.createBillingFlag = !this.isRTPDisabled;
         } else {
           this.createBillingFlag = !oc.order.isCreateBillingEnabled;
         }

         if (!oc.order.isCreateBillingEnabled && oc.order.orderType !== "Quote") {
           this.store.dispatch(OrderConfirmationActions.setCreateBillingEnabled({ createBillingEnabled: false }));
           const billingMessage = "Create Billing is not available because one or more order items require your Parts Manager to approve a price-override in SAP.";
           this.toastService.showToast(billingMessage, ToastType.Warning);
         }
       }
     });

 }

  getOrderDeliveryType(order): string {
    return DeliveryOptions.find(option => option.option.toLowerCase() === order.deliveryOptions.toLowerCase())?.label || "";
  }

  calculateOrderItemValue(orderItem: any, includeCouponValue: boolean = false): number {
    const pricePerUnit = orderItem.finalPrice;

    if (orderItem.couponPartTotal !== 0) {
      return (pricePerUnit * orderItem.quantity) + orderItem.couponPartTotal
        + (includeCouponValue ? this.orderItemCouponValues[orderItem.id] || 0 : 0);
    }

    return pricePerUnit * orderItem.quantity
      + (includeCouponValue ? this.orderItemCouponValues[orderItem.id] || 0 : 0);
  }

  copyToClipBoard(contentToCopy: string) {
    window.navigator['clipboard'].writeText(contentToCopy);
    this.toastService.showToast(`${contentToCopy} copied to clipboard.`, ToastType.Success);
  }

  showCustomerNotes(customerNumber: string): void {
    const modalRef = this.modalService.open(CustomerNotesModalComponent);

    modalRef.componentInstance.customerNumber = customerNumber;
  }

  async downloadInvoice() {
    this.loader.loading = true;
    this.createBillingErrorMsgs = null;

    const sourceName = 'OrderConfirmationComponent_downloadInvoice__Billing';
    const metricName = this.loggerService.getMetricValue(sourceName);
    const appInsightBilling = Object.assign(new AppInsightBilling(), {
      userId: this.commonDataService.User.id,
      customerNumber: this.commonDataService.Customer.customerNumber,
      customerName: this.commonDataService.Customer.customerName,
      branchNumber: this.commonDataService.Branch.code,
      cartDetails: JSON.stringify(this.commonDataService.CartDetails),
      orderNumber: this.order.sapOrderNo,
      PONumber: this.order.pONumber,
      plMetricName: sourceName
    });
    appInsightBilling.products = this.loggerService.getAppInsightParts(this.completeOrderItems, JSON.stringify(appInsightBilling).length);
    this.loggerService.trackMetric(metricName, appInsightBilling);

    await this.cartService.downloadInvoice(this.orderId, this.includeCoreTags)
      .then((oc) => {
        if ( oc.code?.length && !oc.pdf?.length  ) {
          this.createBillingFlag = false;
          this.loader.loading = false;
          const errorCode = oc.code;
          const errorMessage = oc.message;
          if (errorCode === 'error_ibs') {
            this.createBillingErrorMsgs = oc.createBillingErrorMsgs;
            this.showModal();
          } else {
            this.orderConfirmationMessage = errorMessage && errorMessage !== undefined
              && errorMessage != null && errorMessage !== '' ? errorMessage :
              'There were enough parts found to satisfy your request, however the customer has not been approved for billing at this time.';
            this.toastService.showToast(this.orderConfirmationMessage, ToastType.Error);
          }
        }  else if (oc.pdf?.length ) {
          const filename = `Invoice_${this.order.sapOrderNo}.pdf`;
          this.downloadService.downloadPDF(oc.pdf, filename );
          this.loader.loading = false;
          this.createBillingFlag = true;
          this.loggerService.verbose(oc);
          this.toastService.showToast("Invoice Recieved", ToastType.Success);
          this.orderConfirmationMessage = 'There were enough parts found to satisfy your Goods Movement and the customer has been approved for billing.';
          this.toastService.showToast(this.orderConfirmationMessage, ToastType.Success);
        }
      })
      .catch((error) => {
        this.loader.loading = false;
        this.toastService.errorMessage("OrderConfirmationComponent", "downloadInvoice", "downloadInvoice", error);
      });
  }

createPurchaseOrders() {
  const modalRef = this.modalService.open(PurchaseOrderCreateModalComponent, { size: 'lg' });
  modalRef.componentInstance.orderNumber = this.order.sapOrderNo;
  modalRef.componentInstance.isOnlyHotFlagCreatePO = this.hotFlag95And99Data.length;
  modalRef.result
    .then(
      (batchResult: CreatePurchaseOrdersBatchResult) => {
        const successMessages = [];
        const warningMessages = [];

        batchResult.items.forEach((result, index) => {
          if (result.success) {
            let setPurchaseOrderString: string = result.documentType === 'PR' 
              ? `${index + 1} - Create purchase requisition only was executed successfully PR ${result.purchaseOrderNumber}`
              : `${index + 1} - PO ${result.purchaseOrderNumber} created`;
            successMessages.push({
              message: `${setPurchaseOrderString}`,
              type: ToastType.Success,
            });
          } else {
            successMessages.push({
              message: `${index + 1} - PO failed to create`,
              type: ToastType.Error,
            });
          }

          result.messages.forEach((message) => {
            if (message.type === 'success') {
              successMessages.push(this.toastService.toToastMessage(message));
            } else if (message.type === 'warning') {
              warningMessages.push({
                message: `${message.message}`,
                type: "2",
              });
            }
          });
        });


        this.toastService.showMultilineToast(successMessages);

        if (warningMessages.length > 0) {
          this.toastService.showMultilineStaticToast(warningMessages);
        }

        if (batchResult.filename !== undefined && batchResult.blob !== undefined
            && batchResult?.filename?.length > 0 && batchResult?.blob?.size !== 0) {
          this.downloadService.downloadFile(batchResult.filename, batchResult.blob);
        }
        this.printPOFlag = true;
      },
      (error) => { }
    );
}

  async getPickTicket() {

    const sourceName = 'OrderConfirmationComponent_getPickTicket__PrintPickTicket';
    const metricName = this.loggerService.getMetricValue(sourceName);
    const appInsightBilling = Object.assign(new AppInsightBilling(), {
      userId: this.commonDataService.User.id,
      customerNumber: this.commonDataService.Customer.customerNumber,
      customerName: this.commonDataService.Customer.customerName,
      branchNumber: this.commonDataService.Branch.code,
      cartDetails: JSON.stringify(this.commonDataService.CartDetails),
      orderNumber: this.order.sapOrderNo,
      PONumber: this.order.pONumber,
      plMetricName: sourceName
    });
    appInsightBilling.products = this.loggerService.getAppInsightParts(this.completeOrderItems, JSON.stringify(appInsightBilling).length);
    this.loggerService.trackMetric(metricName, appInsightBilling);

    this.loader.loading = true;
    await this.cartService.getPickTicket(this.orderId)
      .then((oc) => {

          this.store.dispatch(OrderConfirmationActions.setCreateBillingEnabled({createBillingEnabled: true}));
          if (oc.pdf?.length) {
            const filename = `PickTicket_${this.order.sapOrderNo}.pdf`;
            this.downloadService.downloadPDF(oc.pdf, filename );
            this.loader.loading = false;
            this.loggerService.verbose(oc);
            this.toastService.showToast("Invoice Recieved", ToastType.Success);
          } else {
            if (oc.messages?.length) {
               this.toastService.showToast( oc.messages , ToastType.Error);
            }
          }

          this.orderConfirmationMessage = 'Print Pick Ticket Created Successfully.';
          this.toastService.showToast(this.orderConfirmationMessage, ToastType.Success);

      },
        (error) => { });
  }

  printDiv(): void {
    let popupWin;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=auto,width=auto');
    popupWin.document.open();
    popupWin.document.write('<!DOCTYPE html><html><head>  '
      + '<style>.billing-table .billing-right span {font-size: 13px;margin: 15px 20px;}.billing-table .billing-right h2 {float: left;font-size: 15px;font-weight: 700;margin: 15px 0;</style>'
      + '<style>body {padding-top: 0;padding-bottom: 30px;background-position: top left;background-repeat: repeat-x;background-size: 150px 150px;font-size: 14px;font-family: TradeGothicLT-CondEighteen;}</style>'
      + '<style>h1 {font-size: 18px;}.table>thead>tr>th {padding: 8px;vertical-align: top!important;border-top: 1px solid #ddd;}.order-billing-summary-text {text-align: left;}</style>'
      + '<style>.top-title {background: #eeb111 none repeat scroll 0 0!important;border-radius: 5px 5px 0 0;color: #000;font-size: 16px;height: 44px;padding: 11px 16px;margin: 20px 0;}</style>'
      + '</head><body onload="window.print()"><div class="reward-body">'
      + this.printContent.nativeElement.innerHTML + '</div></html>');
    popupWin.document.close();
  }

  printSAPQuote() {
    this.loader.loading = true;
    this.quoteService.printSAPQuote(this.orderId, true)
      .then(oc => {
        if (oc.pdf?.length) {
          const filename = `PrintTicket_${this.order.sapOrderNo}.pdf`;
          this.downloadService.downloadPDF(oc.pdf, filename );
          this.loader.loading = false;
          this.loggerService.verbose(oc);
        } else {
          this.loader.loading = false;
          this.orderConfirmationMessage =
          oc.messages  && oc.messages  !== undefined && oc.messages  != null && oc.messages  !== ''
            ? oc.messages
            : 'There were enough parts found to satisfy your request, however the customer has not been approved for billing at this time.';
          this.toastService.showToast(this.orderConfirmationMessage, ToastType.Error);
        }
      },
      (error) => {
      });
  }

  async onCreateBilling(branchCode?: string) {
    if(isColoradoBranch(branchCode)) {
      const modalRef = this.modalService.open(CheckoutBagQuantityComponent, { centered: true, size: 'sm' });
      modalRef.result.then((bagQuantity) => {
        this.bagQuantity = bagQuantity;
        this.createBilling();
      });
    } else {
      await this.createBilling();
    }
  }

  async createBilling() {

    if (this.isWarehouseManaged && this.showWMCreateBillingButton) {

      this.chipClipLineItems = this.orderItems.filter(items =>
        items?.deliveryOption === 'A' &&
        items?.partNumber.indexOf(':90') < 0)
        .map(item => {
          return {
            deliveryOption: item.deliveryOption,
            lineItemId: item.itemNumber,
            partNumber: item.partNumber,
            orderNumber: this.order.sapOrderNo,
            description: item.description,
            price: item.finalPrice,
            quantity: item.quantity,
            storageBin: item?.binLocation?.startsWith('SB-') ? item.binLocation.toUpperCase().replace('SB-', '') :
              null,
            storageType: item?.binLocation?.startsWith('SB-') ? 'BAS' : null
          };
        });

      (await this.populateOverrideBins()).subscribe(() => { });

      if (this.chipClipLineItems?.length > 0 && this.chipClipLineItems.filter(items => items?.storageBin != null).length !== this.orderItems.filter(items =>
        items?.partNumber.indexOf(':90') < 0).length) {
        return this.openChipClipInputModal();
      } else {
        const basketLineItems: CreateBillingRequestLineItem[] =
          this.chipClipLineItems.map(item => {
            const lineItem: CreateBillingRequestLineItem = {
              alterQuantity: item.quantity,
              lineItemId: item.lineItemId,
              orderNumber: this.order.sapOrderNo,
              storageBin: item.storageBin,
              storageType: item.storageType
            };
            return lineItem;
          });

        let request: CreateBillingRequest = {
          orderNumber: this.order.sapOrderNo,
          lineItems: basketLineItems,
          paymentDeviceKey: '',
          checkIntegrations: false
        };

        if (this.isCODeliveryAndBagFeeFeatureEnabled) {
          request = { ...request, ...this.bagQuantity };
        }

        this.createBillingButtonClicked = true;
        return this.store.dispatch(OrderConfirmationActions.displayCustomerReviewForm({request: request}));

      }
    }

    this.createBillingButtonClicked = true;
    return this.downloadInvoice();
  }

  async populateOverrideBins() {
    return this.overrideBins$.pipe(
      switchMap(overrideBins => overrideBins),
      map(overrideBin => {
        const selfPickLineItem = this.orderItems?.filter(items => items?.itemNumber === overrideBin?.ItemNumber).map(item => {
          return {
            deliveryOption: item.deliveryOption,
            lineItemId: item.itemNumber,
            partNumber: item.partNumber,
            orderNumber: this.order.sapOrderNo,
            description: item.description,
            price: item.finalPrice,
            quantity: item.quantity,
            storageBin: overrideBin.StorageBin,
            storageType: overrideBin.StorageType
          } as ChipClipLineItems;
        });
        this.chipClipLineItems.push(selfPickLineItem[0]);
      }),
      takeUntil(this.unsubscribeSubject)
    );

  }

   showModal(): void {
    this.billingOverrideModalRef = this.modalService.open(this.createBillingModal);
  }

  closeModal(): void {
    if (this.billingOverrideModalRef) {
      this.billingOverrideModalRef.close();
    }
  }

  downloadSAPShortCut() {
    this.cartService.downloadSapShortcut(this.order.sapOrderNo)
      .then((oc) => {
        if (oc.file?.length <= 0) {
          this.toastService.errorMessage('OrderConfirmationComponent', 'downloadSAPShortCut', 'downloadSapShortcut', oc);
        } else {
          this.loggerService.verbose(oc.file);
          const mediaType = 'application/x-sapshortcut';
          const blob = new Blob([oc.file], { type: mediaType });
          const filename = `saporder_${this.order.sapOrderNo}.sap`;
          this.downloadService.downloadFile(filename, blob);
        }
      },
        (error) => { });
  }

  clearCustomerValue() {
    const customerRoleName: any = Roles.PartsConnectCustomerUser;
    const isCustomer: boolean = this.authorizationService.hasRole(customerRoleName);
    if (!isCustomer) {
      this.commonDataService.Customer = this.commonDataService.DefaultCustomer;
    }
    this.store.dispatch(BranchActions.setDefaultBranch());
    this.router.navigate(['/']);
  }

  onCreateBillingKeypress(event: KeyboardEvent) {
    const regex = new RegExp('^[a-zA-Z0-9*]+$');
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }

  submitIBSOverrideCreateBilling() {
    this.loader.loading = true;
    this.cartService.submitIBSOverrideCreateBilling(this.orderId, this.order.sapOrderNo, this.authorizationNumber)
      .then( (oc) => {
          if (oc.messages?.length) {

            if ( oc.messages != null && oc.messages !== '') {
              this.closeModal();
              this.toastService.showToast(oc.messages, ToastType.Error);
            }  else {
              this.orderConfirmationMessage = 'There were enough parts found to satisfy your request, however the customer has not been approved for billing at this time.';
              this.toastService.showToast(this.orderConfirmationMessage, ToastType.Error);
            }
          } else {

            const filename = `Invoice_${this.order.sapOrderNo}.pdf`;
            this.downloadService.downloadPDF(oc.pdf, filename );
            this.loader.loading = false;
            this.loggerService.verbose(oc);

            this.orderConfirmationMessage = 'There were enough parts found to satisfy your Goods Movement and the customer has been approved for billing.';
            this.closeModal();
            this.toastService.showToast(this.orderConfirmationMessage, ToastType.Success);
          }
      },
        error => { });
  }

  openChipClipInputModal() {
    const modalRef = this.modalService.open(ChipClipInputModalComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.chipClipLineItems = this.chipClipLineItems;
    return modalRef.result
      .then((result: ChipClipLineItems[]) => {
        const lineItems = result.map((x: ChipClipLineItems) => {
           const lineItem: CreateBillingRequestLineItem = {
            alterQuantity: x.quantity,
            lineItemId: x.lineItemId,
            orderNumber: this.order.sapOrderNo,
            storageBin: x.storageBin,
            storageType: x.storageType
          };
          return lineItem;
        });

        let request: CreateBillingRequest = {
          orderNumber: this.order.sapOrderNo,
          lineItems: lineItems,
          paymentDeviceKey: '',
          checkIntegrations: false,
        };

        if (this.isCODeliveryAndBagFeeFeatureEnabled) {
          request = { ...request, ...this.bagQuantity };
        }

         this.createBillingButtonClicked = true;
         //this.sendBillingRequest(request);
         this.store.dispatch(OrderConfirmationActions.displayCustomerReviewForm({request: request}));
      });
  }

  // sendBillingRequest(request: CreateBillingRequest) {
  //   this.createBillingButtonClicked = true;
  //  // this.loader.loading = true;

  //   this.store.dispatch(OrderConfirmationActions.displayCustomerReviewForm({request: request, totalCharges: this.total}));

  //   // this.cartService.createBilling(request).then(
  //   //   (response: any) => {
  //   //     this.loader.loading = false;

  //   //     this.toastService.showToast("Invoice Recieved", ToastType.Success);
  //   //     this.createBillingFlag = true;
  //   //     this.loggerService.verbose(response);
  //   //     const mediaType = 'application/pdf';
  //   //     const blob = new Blob([response], { type: mediaType });
  //   //     const filename = `Invoice_${this.order.sapOrderNo}.pdf`;
  //   //     this.downloadService.downloadFile(filename, blob);
  //   //   }
  //   // ).catch(error => {
  //   //   this.loader.loading = false;
  //   //   // ToDO : eopoku Letave this commented code in there - we are using fileReader to read the stream data, but apparently it is broken
  //   //   // research needs to be done to determine why it is not working anymore
  //   //   // let reader = new FileReader();
  //   //   // reader.onload = (e) => {
  //   //   //  let parsedError = JSON.parse(e.srcElement["result"]);
  //   //   //  this.toastService.showToast(parsedError["message"], ToastType.Error);
  //   //   // }
  //   //   // reader.readAsText(error);
  //   //   this.toastService.showToast("Incorrect chip-clip or quantity unavailable in bin " + this.orderItems[0].partNumber, ToastType.Error);
  //   // });
  // }

   readyToPick() {
    this.isRTPDisabled = true;
    this.store.dispatch(OrderConfirmationActions.readyToPickButtonPressed());
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
    this.getQueryParametersSub.unsubscribe();
    this.store.dispatch(CartActions.clearCartData());
  }

  deliveryFeeWithCreditVisible(deliveryOptions?: DeliveryType, deliveryFee?: number) {
    if (deliveryFee && deliveryFee !== 0 &&
    (deliveryOptions === 'ShipTo' ||
    deliveryOptions === 'Delivery' ||
    deliveryOptions === 'ShipDirect')) {
      return true;
    }
    return false;
  }

}
