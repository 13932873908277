import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LOYALTY_PROGRAMS } from 'app/app.constants';
import { Branch } from 'entities/branch';
import { CartResult, CartResultLineClient, CartResultLineItem } from 'entities/cart-result';
import { ItemToRemoveFromCart } from 'entities/carts/item-to-remove-from-cart';
import { SapCoupon } from 'entities/coupons/sap-coupon';
import { CurrencyCode } from 'entities/currency-code';
import { Customer } from 'entities/customer';
import { LoyaltyProgram } from 'entities/loyalty-account';
import { User } from 'entities/user';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AutocompleteService } from 'services/auto-complete.service';
import { FeatureFlagService } from 'services/feature-flag.service';
import { ToastService } from 'services/toast.service';
import { AppState } from 'store/app-state';
import * as BranchActions from 'store/branch/branch.actions';
import * as BranchSelectors from 'store/branch/branch.selectors';
import * as CartActions from 'store/cart/cart.actions';
import * as CartSelectors from 'store/cart/cart.selectors';
import * as ConfigurationSelectors from 'store/configuration/configuration.selectors';
import * as CustomerActions from 'store/customer/customer.actions';
import * as CustomerSelectors from 'store/customer/customer.selectors';
import { SelectedCustomer } from 'store/customer/customer.state';
import * as FeatureFlagSelectors from 'store/feature-flags/feature-flags.selectors';
import * as LoyaltyActions from 'store/loyalty/loyalty.actions';
import * as LoyaltySelectors from 'store/loyalty/loyalty.selectors';
import * as PartsBuyoutActions from 'store/parts-buyout/parts-buyout.actions';
import { PartsSearchBaseComponent } from '../part-search-base/parts-search.component.base';
import * as HeaderSearchActions from './header-search.component.actions';

@Component({
  selector: 'header-search',
  templateUrl: './header-search.component.html'
})
export class HeaderSearchComponent extends PartsSearchBaseComponent {
  isInternal$: Observable<boolean> = this.store.select(ConfigurationSelectors.isInternal);
  isCustomerNotesFeatureEnabled: boolean = false;
  loyaltyEnabled$: Observable<boolean> = this.store.select(FeatureFlagSelectors.isFeatureActive('Loyalty'));
  currentUser$: Observable<User> = this.store.select(ConfigurationSelectors.selectUserInfo);
  customer$: Observable<SelectedCustomer> = this.store.select(CustomerSelectors.selectedCustomer);
  branch$: Observable<Branch> = this.store.select(BranchSelectors.selectedBranch);
  cartData$: Observable<CartResult> = this.store.select(CartSelectors.selectCart);
  cartItems$: Observable<CartResultLineClient[]> = this.store.select(CartSelectors.selectCartPartItems);
  quoteNumber$: Observable<string> = this.store.select(CartSelectors.selectQuoteNumber);
  isQuote$: Observable<boolean> = this.store.select(CartSelectors.selectIsQuote);
  cartCouponTotal$: Observable<number> = this.store.select(CartSelectors.selectCartCouponTotal);
  includesCouponData$: Observable<boolean> = this.store.select(CartSelectors.hasSapCoupons);
  cartCoupons$: Observable<SapCoupon[] | null> = this.store.select(CartSelectors.selectCartCoupons);
  subTotal$: Observable<number> = this.store.select(CartSelectors.selectCartSubtotal);
  cartId$: Observable<string> = this.store.select(CartSelectors.selectedCartId);
  quoteExpirationDate$: Observable<Date> = this.store.select(CartSelectors.selectQuoteExpirationDate);
  isDefaultCustomer$: Observable<boolean> = this.store.select(CustomerSelectors.isDefaultCustomer);
  hasUnsavedChanges$: Observable<boolean> = this.store.select(CartSelectors.hasUnsavedChanges);
  currencyCode$: Observable<CurrencyCode> = this.store.select(BranchSelectors.selectCurrencyCode);
  cartLoading$: Observable<boolean> = this.store.select(CartSelectors.cartLoading);
  numberOfLoyaltyAccounts$: Observable<number> =  this.store.select(LoyaltySelectors.loyaltyAccounts).pipe(
    map(x => x.length)
  );

  public showCarts: boolean;
  public searchTerm: string = '';

  public hasLoyaltyProgram$: Observable<boolean> = this.branch$
    .pipe(
      map((branch) => this.loyaltyPrograms.some((loyaltyProgram) => branch?.brands?.includes(loyaltyProgram)))
    );

  constructor(
    public toastService: ToastService,
    public router: Router,
    private store: Store<AppState>,
    private featureFlagService: FeatureFlagService,
    private activatedRoute: ActivatedRoute,
    public autoCompleteService: AutocompleteService,
    @Inject(LOYALTY_PROGRAMS) private loyaltyPrograms: LoyaltyProgram[],
  ) {
    super(
      toastService,
      router,
      autoCompleteService
    );
    this.isCustomerNotesFeatureEnabled = featureFlagService.isFeatureActive("FeatureFlag.CustomerNotes.Enabled");
    const searchTermQueryParam = this.activatedRoute.snapshot.queryParams['searchTerm'];
    this.searchTerm = searchTermQueryParam;
    this.updateSearchTerm(searchTermQueryParam);
  }

  // TODO: Use form submit instead
  onSearchTermKeypress(event) {

  }

  partBuyoutModal() {
    this.store.dispatch(PartsBuyoutActions.opetPartsBuyoutModal());
  }

  removeCartItems(selectedCartItem: CartResultLineItem) {
    const removeCartItems: ItemToRemoveFromCart[] = new Array<ItemToRemoveFromCart>();
    const item: ItemToRemoveFromCart = {
      cartItemId: selectedCartItem.cartItemId
    };
    removeCartItems.push(item);


    this.store.dispatch(CartActions.removeItemsFromCart({
      isPickupDeliveryType: false,
      itemsToRemoveFromCart: removeCartItems
    }));
  }

  selectDefaultCustomer() {
    this.store.dispatch(HeaderSearchActions.selectDefaultCustomer());
  }

  onCustomerSelect(customer: Customer) {
    this.store.dispatch(HeaderSearchActions.selectCustomer({ customer }));
  }

  onBranchSelect(branch: Branch) {
    this.store.dispatch(BranchActions.selectBranch({ branch }));
  }

  onMyCartClick() {
    this.showCarts = false;
    this.store.dispatch(LoyaltyActions.getLoyaltyDiscounts());
    this.router.navigate(['/cart']);
  }

  onMyCartCheckoutClick() {
    this.showCarts = false;
    this.router.navigate(['/cart']);
  }

  deleteCart(cartId: string) {
    this.store.dispatch(CartActions.deleteCart({ cartId: cartId, routeTo: "/" }));
  }

  showCustomerNotes(customerNumber: string): void {
    this.store.dispatch(CustomerActions.getCustomerNotes({ customerNumber }));
  }

  selectLoyaltyAccounts() {
    this.store.dispatch(LoyaltyActions.openLoyaltyAccountModal());
  }
}
