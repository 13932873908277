import { Component, Input } from '@angular/core';
import { Branch } from 'entities/branch';

@Component({
  selector: 'order-branch',
  templateUrl: './order-branch.component.html',
  styles: []
})
export class OrderBranchComponent {
  @Input() branch: Branch;

  constructor() { }

}
