import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { CartResultLineItemSelection } from 'entities/cart-result';
import { CurrencyCode } from 'entities/currency-code';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonDataService } from 'services/common-data.service';
import { LoaderService } from 'services/loader.service';
import { ToastService } from 'services/toast.service';
import { VendorService } from 'services/vendor.service';
import { AppState } from 'store/app-state';
import * as BranchSelectors from "store/branch/branch.selectors";
import * as MyCartActions from 'store/my-cart/my-cart.actions';
import * as MyCartSelectors from 'store/my-cart/my-cart.selectors';
import * as MyCartComponentActions from '../my-cart/my-cart.component.actions';
import { MyCartComponentStore } from '../my-cart/my-cart.component.store';

type BuyoutCartResultLineItemSelection = CartResultLineItemSelection
 & {
    showVendorPanel: boolean;
    continueWithLessSalesPrice: boolean;
 }

@Component({
    selector: 'known-part-buyout',
    templateUrl: './known-part-buyout.component.html'
})
export class KnownPartBuyoutComponent implements OnInit {

    private filterSubject = new BehaviorSubject<string>('');
    filterChangeAction$: Observable<string> = this.filterSubject.asObservable();

    selectedCartItems$: Observable<BuyoutCartResultLineItemSelection[]> = this.store.select(MyCartSelectors.selectedCartItems).pipe(
      map((x: BuyoutCartResultLineItemSelection[]) => ([...x.map(y => ({...y}))]))
    );

    filteredCartItems$: Observable<BuyoutCartResultLineItemSelection[]> = combineLatest([this.selectedCartItems$, this.filterChangeAction$]).pipe(
        map(([selectedCartItems, filterString]) => selectedCartItems.filter( selectedCartItem => {
            if( filterString === ''){
                return true;
            }
            return (selectedCartItem.partNumber && selectedCartItem.partNumber.toLowerCase().includes(filterString.toLocaleLowerCase()))         ||
                   (selectedCartItem.corePartNumber && selectedCartItem.corePartNumber.toLowerCase().includes(filterString.toLocaleLowerCase())) ||
                   (selectedCartItem.vendorName && selectedCartItem.vendorName.toLowerCase().includes(filterString.toLocaleLowerCase()));
            }))
      );

    filterKey: string = '';
    partItemTobeChanged: any = null;
    partNumber: string = '';
    isBuyoutGreaterthanSales: boolean = false;
    branchCode: string;

    fixedVendor$: Observable<boolean> = this.store.select(MyCartSelectors.selectedCartItems).pipe (
      map( selectedItems => !selectedItems.some( selected => selected.vendorCode?.length === 0 ))
    );

    firstPartWithFixedVendor$: Observable<string> = this.store.select(MyCartSelectors.selectedCartItems).pipe(
      map( (cartItem) => cartItem.find(x => x.vendorCode?.length === 0)?.partNumber)
    );

    public currencyCode$: Observable<CurrencyCode> = this.store.select(BranchSelectors.selectCurrencyCode);
    public currencySymbol$: Observable<string> = this.store.select(BranchSelectors.selectCurrencySymbol);

    constructor(
        private myCartComponentStore: MyCartComponentStore,
        private store: Store<AppState>,
        private loader: LoaderService,
        private toastService: ToastService,
        private commonDataService: CommonDataService,
        private vendorsService: VendorService,
        private activeModal: NgbActiveModal
    ) { }

    ngOnInit() {

        this.partItemTobeChanged = null;
        this.store.dispatch(MyCartActions.getVendorPrices({vendorCode: null}));
    }

    public closeModal(): void {
        this.activeModal.close();
    }

    filter = function () {
        this.filterSubject.next(this.filterKey);

    };

    trackById(item: any) {
        return item.partNumber;
    }

    removeCartItem(cartItem: BuyoutCartResultLineItemSelection) {
      const itemIds: string[] = [cartItem.cartItemId];
      this.store.dispatch(MyCartComponentActions.removeCartItemsSuccess({ itemIds }));
      this.store.dispatch(MyCartActions.toggleSelection({ cartItemId: itemIds[0] }));
    }

    confirmPartsBuyout() {
      this.store.dispatch(MyCartActions.confirmPartsBuyout());
    }

    knownPartPriceKeyPress(e: KeyboardEvent) {
        this.isBuyoutGreaterthanSales = false;
        let priceVal: any = (e.currentTarget as HTMLInputElement).value;

        // allows 0-9, backspace, and decimal
        if (((e.which < 48 || e.which > 57) && e.which != 8 && e.which != 46)) {
            return false;
        }

        // checks to make sure only 1 decimal is allowed
        if (e.which == 46) {
            if (priceVal.indexOf('.') != -1) {
                return false;
            }
        }

        // if (/^\d*(\.\d\d)$/.test(priceVal)) {
        //    return false;
        // }
    }

    coreknownPartPriceKeyPress(e: KeyboardEvent) {
        this.isBuyoutGreaterthanSales = false;
        let priceVal: any = (e.currentTarget as HTMLInputElement).value;

        // allows 0-9, backspace, and decimal
        if (((e.which < 48 || e.which > 57) && e.which != 8 && e.which != 46)) {
            return false;
        }

        // checks to make sure only 1 decimal is allowed
        if (e.which == 46) {
            if (priceVal.indexOf('.') != -1) {
                return false;
            }
        }

    }

    ShowWarningMessage(cartItem: BuyoutCartResultLineItemSelection) {
        this.isBuyoutGreaterthanSales = ((Number(cartItem.knownPartPrice) < Number(cartItem.knownPartBuyoutAmount)) ||
            (Number(cartItem.coreknownPartPrice) < Number(cartItem.coreknownPartBuyoutAmount))) ? true : false;
    }

    knownPartPriceOnPaste(cartItem: BuyoutCartResultLineItemSelection, e: ClipboardEvent) {
        let content = e.clipboardData.getData('text/plain');
        this.knownPartBuyoutCorrectPrice(cartItem, content);
        return false;
    }

    coreknownPartPriceOnPaste(cartItem: BuyoutCartResultLineItemSelection, e: ClipboardEvent) {
        let content = e.clipboardData.getData('text/plain');
        this.coreknownPartBuyoutCorrectPrice(cartItem, content);
        return false;
    }

    customerCorePriceOnPaste(cartItem: BuyoutCartResultLineItemSelection, e: ClipboardEvent) {
        let content = e.clipboardData.getData('text/plain');
        this.customerCorePriceCorrectPrice(cartItem, content);
        return false;
    }

    customerCorePriceCorrectPrice(cartItem: BuyoutCartResultLineItemSelection, textPrice: string) {
        let match: any = textPrice.match(/\.?[0-9]\d*(\.\d{1,2})?/g);
        cartItem.continueWithLessSalesPrice = false;
        if (match && match.length > 0) {
            // @ts-ignore
            cartItem.coreknownPartPrice = textPrice > 0 ? textPrice : '1.00';
        }
    }

    knownPartCorrectPrice(cartItem: BuyoutCartResultLineItemSelection, textPrice: string) {
        let match: any = textPrice.match(/\.?[0-9]\d*(\.\d{1,2})?/g);
        // @ts-ignore
        cartItem.knownPartPrice = '1.50';
        cartItem.continueWithLessSalesPrice = false;
        if (match && match.length > 0) {
            cartItem.knownPartPrice = match[0] > 0 ? match[0] : '1.50';
            this.store.dispatch(MyCartActions.updateBuyoutCartItem({cartItem}));
        }
        this.ShowWarningMessage(cartItem);
    }

    knownPartBuyoutCorrectPrice(cartItem: BuyoutCartResultLineItemSelection, textPrice: string) {
        let match: any = textPrice.match(/\.?[0-9]\d*(\.\d{1,2})?/g);
        // @ts-ignore
        cartItem.knownPartBuyoutAmount = '1.00';
        cartItem.continueWithLessSalesPrice = false;
        if (match && match.length > 0) {
            cartItem.knownPartBuyoutAmount = match[0] > 0 ? match[0] : '1.00';
            // @ts-ignore
            cartItem.knownPartPrice = textPrice > 0 ? (parseFloat((textPrice * 1.5).toFixed(2))).toString() : '1.50';
            this.store.dispatch(MyCartActions.updateBuyoutCartItem({cartItem}));
            this.isBuyoutGreaterthanSales = false;
        }
    }

    coreknownPartBuyoutCorrectPrice(cartItem: BuyoutCartResultLineItemSelection, textPrice: string) {
        let match: any = textPrice.match(/\.?[0-9]\d*(\.\d{1,2})?/g);
        // @ts-ignore
        cartItem.coreknownPartBuyoutAmount = '1.00';
        cartItem.continueWithLessSalesPrice = false;
        if (match && match.length > 0) {
            cartItem.coreknownPartBuyoutAmount = match[0] > 0 ? match[0] : '1.00';
            // @ts-ignore
            cartItem.coreknownPartPrice = textPrice > 0 ? (parseFloat((textPrice * 1.33).toFixed(2))).toString() : '1.33';
        }
    }

    changeVendor(cartItem: BuyoutCartResultLineItemSelection) {
        cartItem.showVendorPanel = true;
        this.partItemTobeChanged = cartItem;
    }

    onKnownPartVendorSelect(vendor: any) {
      this.store.dispatch(MyCartActions.getVendorPrices({vendorCode: vendor.number}));
    }
}
