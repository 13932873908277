<ng-container *ngLet="{currentBranch: branch$ | async, isQuote: isQuote$ | async, part: part$ | async, isFavorite: isFavorite$ | async,
                      displayPartNumber: displayPartNumber$ | async, canReturnCore: canReturnCore$ | async,
                      filteredBinLocations: filteredBinLocations$ | async, hasFixedBinLocations: hasFixedBinLocations$ | async,
                      sapCuratedNote: sapCuratedNote$ | async, notes: notes$ | async, alternateParts: alternateParts$ | async,
                      relatedParts: relatedParts$ | async, noteCount: noteCount$ | async, isInternal: isInternal$ | async, cartIsLocked: cartIsLocked$ | async} as vm">
  <div class="product-details gy-3">
    <!--product-card Start-->
    <div *ngIf="vm.part" class="product-card border row g-0 bg-white"
      [ngClass]="{ 'disabled-card': !vm.part.quantityAvailable }">
      <div class="col-12 col-md-4">
        <h4 class="product-card-title m-0 px-3 py-2 d-flex justify-content-between">
          <span id="partDetails_span_PartNumber" class="strong">{{ vm.displayPartNumber }}</span>
          <div>
            <a id="partDetails_a_RemoveFromFavorites" *ngIf="vm.isFavorite" role="button"
              href="javascript:void(0)" title="Remove from Favorites"
              (click)="removeFavoritePart(vm.part); $event.stopPropagation()">
              <i class="fas fa-star"></i>
            </a>
            <a id="partDetails_a_AddFromFavorites" *ngIf="!(vm.isFavorite)" role="button"
              href="javascript:void(0)" title="Add to Favorites"
              (click)="addToFavoriteParts(vm.part); $event.stopPropagation()">
              <i class="far fa-star"></i>
            </a>
            &nbsp;
            <a id="partDetails_a_CopyPartNumberToClipboard" role="button" href="javascript:void(0)"
              title="Copy Part Number to Clipboard" (click)="copyPartNumberToClipBoard(vm.part); $event.stopPropagation()">
              <i class="far fa-clone"></i>
            </a>
          </div>
        </h4>
        <div class="product-image product-image-lg">
          <image-feedback id="partDetails_image-feedback_ImageFeedbackActiveActive" [image]="vm.part.image"
            (close)="isImageFeedbackActive = false" *ngIf="isImageFeedbackActive"></image-feedback>

          <a id="partDetails_a_ImageFeedbackActiveActiveAndUserInternal"
            *ngIf="!isImageFeedbackActive && vm.isInternal" class="product-image-feedback px-3 py-2"
            href="javascript:void(0)" (click)="isImageFeedbackActive = true">
            Image Feedback
          </a>

          <img [src]="getImage(vm.part.image, imageBaseUrl, '370X370')" [default]="'assets/img/370X370_No_Image.png'" tabindex="-1" alt="No Image" >
        </div>
      </div>

      <div class="col-12 col-md-8 px-1 bg-light">
        <!-- Nav tabs -->
        <ul ngbNav #partNav="ngbNav" class="nav-tabs">
          <li ngbNavItem>
            <a ngbNavLink>Details</a>
            <ng-template ngbNavContent>
              <div class="container-fluid d-flex flex-column">
                <div class="row flex-grow-1">
                  <div class="col-12 col-md-8">
                    <div class="d-flex justify-content-between py-3">
                      <h4 id="partDetails_h4_DetailIsReman" class="strong">{{vm.part.description}}<sup *ngIf="vm.part.isReman"
                          class="text-danger">REMAN</sup></h4>
                      <div>
                        <strong id="partDetails_strong_QuantityAvailable">{{vm.part.quantityAvailable}} Available</strong>
                        <div>{{vm.currentBranch.code}} - {{vm.currentBranch.displayName}}</div>
                      </div>
                    </div>
                    <div>
                      <h5 class="strong text-muted">PART INFORMATION</h5>
                      <div class="row">
                        <div class="col-12 col-md-6">
                          <h5 class="strong">VMRS DESCRIPTION</h5>
                          <p style="max-height: 55px; overflow-y: auto;">{{vm.part.vmrsDescription}}</p>
                        </div>
                        <div class="col-12 col-md-6">
                          <h5 class="strong">VMRS CODE</h5>
                          <p>{{vm.part.vmrsCode}}</p>
                        </div>

                        <div class="col-12 col-md-6">
                          <h5 class="strong">BASE MFR</h5>
                          <p>{{vm.part.baseManufacturer}}</p>
                        </div>
                        <div class="col-12 col-md-6">
                          <h5 class="strong">BASE MFR PART NUMBER</h5>
                          <p>{{vm.part.baseManufacturerPartNumber}}</p>
                        </div>
                        <div class="col-12 col-md-6">
                          <h5 class="strong">PREFERRED RUSH ALTERNATE</h5>
                          <ng-container *ngFor="let alternatePartNumber of vm.part.altPartNumbers | keyvalue">
                            <p *ngIf="vm.currentBranch.brands?.includes(alternatePartNumber.key)">
                              {{vm.alternatePartNumber.value}}</p>
                          </ng-container>
                          <div *ngIf="vm.part.hazmat" class="alert alert-danger" id="hazmat-errors">
                            <span><i class="fas fa-exclamation-triangle"></i> Hazmat products not eligible for freight delivery. In-store pickup and</span> <br>
                            <span>local delivery are still available for this product.</span>
                          </div>
                        </div>

                        <div class="col-12 col-md-6" *ngIf="vm.part.corePart">
                          <h5 class="strong">CORE PART NO</h5>
                          <p>{{vm.part.corePart}}</p>
                          <div id="partDetails_div_DetailCoreOption" *ngIf="vm.part.corePrice>0">
                            <select id="partDetails_select_DetailCoreOption" class="form-control"
                              [ngModel]="vm.part.coreOption" (ngModelChange)="changeCoreOption($event)"
                              [disabled]="!(vm.canReturnCore)">
                              <option id="partDetails_option_DetailNoCoreReturned" value="NOCORER">No Core Returned
                              </option>
                              <option id="partDetails_option_DetailCoreReturned" value="CORE1">Core Returned</option>
                            </select>
                          </div>
                        </div>
                        <ng-container *ngLet="vm.filteredBinLocations as filteredBinLocations">
                          <span *ngIf="binLocationsLoadingSubject | async" class="countLoader ms-3"></span>
                          <div id="partDetails_div_WarehouseManagementBinLocation"
                            *ngIf="vm.currentBranch.isWarehouseManaged && vm.isInternal; else nonWarehouseManagedBinlocation;"
                            class="col-12 col-md-6 mb-3">
                            <h5 *ngIf="(vm.hasFixedBinLocations) || vm.part.quantityAvailable > 0" class="strong hyperlink" (click)="showBinLocations()">
                              BIN LOCATION</h5>
                            <div class="container nopadding" *ngFor="let binLoc of filteredBinLocations;">
                              <div class="row">
                                <div class="col-1">{{binLoc.storageType}}</div>
                                <div class="col-5">{{binLoc.binLocation}}</div>
                                <div class="col-2">{{binLoc.quantityAvailable}}</div>
                                <div class="col-2">
                                  <span *ngIf="binLoc.fixedBin"
                                    style="background-color: black; color: white; padding: 3px">Fixed</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                        <ng-template #nonWarehouseManagedBinlocation>
                          <div id="partDetails_div_BinLocation"
                            *ngIf="vm.isInternal && vm.part.binLocation" class="col-12 col-md-6">
                            <h5 class="strong">BIN LOCATION</h5>
                            <p>{{vm.part.binLocation}}</p>
                          </div>
                        </ng-template>

                        <div id="partDetails_div_Comments" *ngIf="vm.part.comments" class="col-12 col-md-6">
                          <h5 class="strong"></h5>
                          <p>Your search of {{vm.part.comments}}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-4 border-start bg-price pricingContainer">
                    <!--change PRICE-->
                    <form id="partDetails_form_QuantityPrice" #QuantityPriceForm="ngForm">
                      <div class="px-3 py-3 gy-2">
                        <pricing id="partDetails_pricing_PriceAdjust" [part]="vm.part" (partChange)="changePart($event)"
                          [branch]="vm.currentBranch" idPrefix="partDetail" priceAdjust (extend)="onExtendPart($event)"></pricing>
                        <div class="gy-2" *ngIf="vm.part.isRushPart && vm.part.isExtendedToBranch">
                          <div class="strong text-muted">Quantity</div>
                          <div class="input-group spinner">
                            <part-quantity
                              inputName="partDetails_text_Quantity"
                              [value]="vm.part.quantity"
                              (onChangeQuantity)="changeQuantity($event)"
                              #primaryPartQuantity
                            ></part-quantity>
                          </div>
                          <div>
                            <button id="partDetails_button_AddToCartSubmit" type="submit" class="btn btn-primary"
                              [disabled]="vm.cartIsLocked || (primaryPartQuantity.value < 1)" (click)="addToCart(vm.part)">
                              {{!vm.part.quantityAvailable && vm.isInternal ? "Order" : "Add to Cart"}}
                            </button>
                          </div>
                        </div>
                        <div>
                          <a id="partDetails_a_NationalInventory" role="button" class="hyperlink"
                            href="javascript:void(0)" (click)="openNationalInventory(vm.part)"
                            *ngIf="!vm.isQuote && vm.part.isRushPart">National Inventory
                          </a>
                        </div>
                      </div>
                    </form>
                    <!--// change PRICE-->
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li *ngIf="authorizationService.hasPermission(permission.ReadProductResearch)" ngbNavItem>
            <a ngbNavLink id="partDetails_tab_ProductResearch">Product Research</a>
            <ng-template ngbNavContent>
              <div class="px-3">
                <div class="d-flex justify-content-between py-3">
                  <h4 id="partDetails_h4_ProductResearchIsReman" class="strong">{{vm.part.description}}<sup
                      *ngIf="vm.part.isReman" class="text-danger">REMAN</sup></h4>
                  <div>
                    <strong>{{vm.part.quantityAvailable}} Available</strong>
                    <div>{{vm.currentBranch.code}} - {{vm.part.branchName}}</div>
                  </div>
                </div>
                <div>
                  <h5 class="strong text-muted">Links</h5>
                  <div class="row g-0">
                    <div class="col-12 col-md-6 mb-3">
                      <a id="partDetails_a_PaccarEPortal" [href]="paccarUrl" class="hyperlink"
                        target="_blank">Paccar
                        E-Portal
                      </a>
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                      <a id="partDetails_a_Parts" href="https://evalue.internationaldelivers.com/partsonline/"
                        class="hyperlink" target="_blank">Navistar
                        Parts
                      </a>
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                      <a id="partDetails_a_AllMakesPartsCatalog" href="https://partscatalog.rushtruckcenters.com/"
                        class="hyperlink" target="_blank">All-Makes Parts Catalog</a>
                    </div>
                    <!--ToDo: made changes to hide this url because of this feature moved to MVP3 Enhancements, mainly due to multiple manufacturers -->
                    <div class="col-12 col-md-6 mb-3" style="display: none;">
                      <a id="partDetails_a_ManufacturerPortal" href="http://www.delcoremy.com" class="hyperlink"
                        target="_blank">Manufacturer
                        Portal
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li *ngIf="authorizationService.hasPermission(permission.ReadPartNotes)" ngbNavItem>
            <a ngbNavLink id="partDetails_tab_ReadPartNotes">
              <div class="d-flex align-items-center gx-1">
                <span>Notes</span>
                <h6 id="partDetails_h6_ReadPartNotesAllNotes" class="m-0">
                  <span class="badge bg-secondary small">{{vm.noteCount}}</span>
                </h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <part-notes [partNumber]="vm.part.rushPartNumber" [sapCuratedNote]="vm.sapCuratedNote"
                [notes]="vm.notes" (update)="getNotes()"></part-notes>
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink id="partDetails_tab_Specifications">Specifications</a>
            <ng-template ngbNavContent>
              <div class="p-3">
                <ng-container id="partDetails_container_SpecificationsNotAvailable"
                  *ngIf="!vm.part.specification || !vm.part.specification.length || !vm.part.specification[0]">
                  Specifications are not available.
                </ng-container>
                <ng-container id="partDetails_container_SpecificationsAvailable"
                  *ngIf="vm.part.specification && vm.part.specification.length && vm.part.specification[0]">
                  <h4 class="strong">Specifications</h4>
                  <div class="row g-0">
                    <div id="partDetails_div_SpecificationItem_{{i}}"
                      *ngFor="let specItem of vm.part.specification; let i = index" class="col-12 col-md-6">
                      <p>{{specItem}}</p>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="partNav"></div>
      </div>
    </div>
    <!--product-card End-->

    <div id="partDetails_div_LoadingAlternateParts" *ngIf="!(vm.alternateParts)">
      <h5 class="ps-4">Loading alternate part(s) ...</h5>
    </div>

    <div id="partDetails_div_LoadingRelatedParts" *ngIf="!(vm.relatedParts)">
      <h5 class="ps-4">Loading related part(s) ...</h5>
    </div>

    <div>
      <ul ngbNav #suggestedPartsNav="ngbNav" class="nav-tabs" activeId="vm.relatedParts">
        <li ngbNavItem="vm.relatedParts" *ngIf="vm.relatedParts?.length">
          <a ngbNavLink id="partDetails_tab_RelatedParts">Related/Suggested Parts</a>
          <ng-template ngbNavContent>
            <part-card-list *ngIf="!relatedAlternatePartsLoading;" [parts]="vm.relatedParts" [partType]="relatedPartType" (partChange)="changeFavorite($event, 'relatedParts')"></part-card-list>
          </ng-template>
        </li>
        <li ngbNavItem="alternateParts" *ngIf="vm.alternateParts?.length">
          <a ngbNavLink id="partDetails_tab_AlternateParts">
            <div class="d-flex align-items-center gx-1">
              <span>Alternate Parts</span>
              <h6 class="m-0">
                <span class="badge bg-secondary small">{{vm.alternateParts.length}}</span>
              </h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <part-card-list [parts]="vm.alternateParts" [partType]="alternatePartType" [sourcePart]="vm.part" (partChange)="changeFavorite($event, 'alternateParts')">
            </part-card-list>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="suggestedPartsNav"></div>
    </div>
  </div>
</ng-container>
