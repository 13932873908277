import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Branch } from 'entities/branch';
import { CartResult } from 'entities/cart-result';
import { CartSearch } from 'entities/cart-search';
import { AddCartItemRequest } from 'entities/carts/add-cart-item-request';
import { ErrorInformation } from 'entities/error-information';
import { OrderDetails } from 'entities/order-details';
import { OrderDetailsLineItem } from 'entities/order-details-line-item';
import { User } from 'entities/user';
import { blankId } from 'helpers/blank-id';
import { BuyAgainPopoverMessageModalComponent } from 'modals/popover-message-modal/buyagain-popover-message-modal.component';
import { combineLatest, EMPTY, from, merge, Observable, of, Subject } from 'rxjs';
import { catchError, filter, first, map, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CartService } from 'services/cart.service';
import { ConfigurationService } from 'services/configuration.service';
import { FeatureFlagService } from 'services/feature-flag.service';
import { LoaderService } from 'services/loader.service';
import { ToastService } from 'services/toast.service';
import { AppState } from 'store/app-state';
import * as BranchSelectors from 'store/branch/branch.selectors';
import * as CartActions from 'store/cart/cart.actions';
import * as CartSelectors from 'store/cart/cart.selectors';
import * as CustomerActions from "store/customer/customer.actions";
import * as CustomerSelectors from 'store/customer/customer.selectors';
import { SelectedCustomer } from 'store/customer/customer.state';
import * as BuyAgainComponentActions from './buy-again.component.actions';

@Component({
  selector: 'buy-again',
  templateUrl: './buy-again.component.html',
  styleUrls: ['./buy-again.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuyAgainComponent {
  @Input() set orderDetails(value: OrderDetails) {
    this._orderDetails = value;
    this.isBuyAgainDisabled = this.getIsBuyAgainDisabled(this._orderDetails);
  }
  @Output() closeModal: EventEmitter<void> = new EventEmitter();

  public isBuyAgainDisabled: boolean = false;
  private _orderDetails: OrderDetails;

  private earlyCartExists$: Observable<string> = this.store.select(CartSelectors.selectCart)
    .pipe(
      first(),
      withLatestFrom(this.store.select(CustomerSelectors.selectedCustomer), this.store.select(BranchSelectors.selectedBranch)),
      map(([cartState, customer, branch]: [CartResult, SelectedCustomer, Branch]): CartSearch => ({
        customerNumber: customer.customerNumber,
        branchCode: branch.code,
        cartId: cartState?.cartId,
      })),
      switchMap((cartSearch) => this.cartService.getCarts(cartSearch)
        .pipe(
          map(({ cartId }) => cartId !== blankId
            ? cartId
            : null
          )
        )
      ),
      shareReplay(1)
    );

  private buyAgainSubject: Subject<OrderDetails> = new Subject();
  public buyAgain$: Observable<CartResult> = this.buyAgainSubject
    .pipe(
      // If Cart already exists, show confirmation modal
      switchMap((orderDetails: OrderDetails) => combineLatest([this.earlyCartExists$, this.configurationService.user$])
        .pipe(first(), map(([cartId, user]: [string, User]) => ({ orderDetails, cartId, user })))
      ),
      switchMap(({ orderDetails, cartId, user }: { orderDetails: OrderDetails, cartId: string, user: User }) => {
        if (cartId && !user.isInternal) {
          return from(this.showBuyAgainModal(orderDetails))
            .pipe(
              filter((result: boolean) => result === true),
              map(result => ({ orderDetails, cartId })
              ));
        }
        return of({ orderDetails, cartId });
      }),
      tap(() => this.loaderService.setLoading(true)),
      switchMap(({ orderDetails, cartId }) =>
        this.store.select(BranchSelectors.selectedBranch)
          .pipe(
            first(),
            switchMap((branch: Branch) => {
              const createCartRequest = {
                branchCode: branch.code,
                customerNumber: orderDetails.orderHistory.customerNumber,
                forceCreate: true
              };
              return this.cartService.getOrCreateCart(createCartRequest);
            }),
            map(({ cartId }: { cartId: string }) => ({ orderDetails, cartId }))
          )
      ),
      map(({ orderDetails, cartId }: { orderDetails: OrderDetails, cartId: string }) => ({ cartId, requests: this.convertToBatchAddCartItemRequest(orderDetails) })),
      switchMap(({ cartId, requests }: { cartId: string, requests: AddCartItemRequest[] }) => this.cartService.batchAddToCart(cartId, requests)
        .pipe(
          tap((cartResult: CartResult) => {
            this.store.dispatch(CustomerActions.clearCustomer());
            this.store.dispatch(CartActions.clearCartData());

            this.store.dispatch(CustomerActions.findCustomers({
              parentSource: "",
              searchTerm: "",
              customerNumber: cartResult.customerNumber,
              customerName: "",
              searchCity: "",
              searchState: "",
              searchPostalCode: "",
              searchPhoneNumber: ""
            }));

            this.store.dispatch(BuyAgainComponentActions.buyAgainSuccess({ cartId }));
            this.store.dispatch(CartActions.getCartDataSuccess({ cartResult }));
            this.store.dispatch(CartActions.selectOpenCart({ openCartId: cartId, navigateToMyCart: true }));
          }),
          catchError((error: ErrorInformation) => {
            this.toastService.errorMessage("BuyAgainComponent", "batchAddToCart", "batchAddToCart", error);
            this.loaderService.setLoading(false);
            return EMPTY;
          })
        )
      ),
      tap(() => {
        this.loaderService.setLoading(false);
        this.closeModal.emit();
      })
    );

  public data$: Observable<string | CartResult> = merge(this.buyAgain$, this.earlyCartExists$);

  constructor(
    private store: Store<AppState>,
    private cartService: CartService,
    private toastService: ToastService,
    private configurationService: ConfigurationService,
    private loaderService: LoaderService,
    private router: Router,
    private modalService: NgbModal,
    private featureFlagService: FeatureFlagService,
  ) { }

  convertToBatchAddCartItemRequest(orderDetails: OrderDetails): AddCartItemRequest[] {
    return orderDetails.lineItems
      .filter((lineItem: OrderDetailsLineItem) => lineItem.isInventoried && !lineItem.isCore)
      .map((lineItem: OrderDetailsLineItem) => this.convertToAddCartItem(lineItem));
  }


  convertToAddCartItem(lineItem: OrderDetailsLineItem): AddCartItemRequest {
    return {
      binLocation: '',
      coreOption: "NOCORER",
      partId: null,
      partNumber: lineItem.partNumber,
      quantity: lineItem.quantity,
      trackingParameters: '',
      adjustedPrice: 0,
      description: lineItem.description,
      sapCoupons: lineItem.sapCoupons
    };
  }


  private getBuyableLineItems(lineItems: OrderDetailsLineItem[]): OrderDetailsLineItem[] {
    return lineItems.filter((lineItem: OrderDetailsLineItem) => !lineItem.isCore && lineItem.isInventoried && !lineItem.isHidden);
  }

  private getIsBuyAgainDisabled(orderDetails: OrderDetails): boolean {
    if (!orderDetails || !orderDetails.lineItems || !orderDetails.lineItems.length) {
      return true;
    }

    const buyableLineItems: OrderDetailsLineItem[] = this.getBuyableLineItems(orderDetails.lineItems);

    return !buyableLineItems.length;
  }

  private showBuyAgainModal(orderDetails: OrderDetails): Promise<boolean> {
    // Display the modal and only pass the order through if accepted
    const modalRef: NgbModalRef = this.modalService.open(BuyAgainPopoverMessageModalComponent, { size: 'sm', backdrop: 'static' });
    modalRef.componentInstance.message = "Would you like to add these items to your existing cart?";
    modalRef.componentInstance.orderDetailsLineItems = orderDetails.lineItems.filter(lineItem => !lineItem.isHidden && lineItem.isInventoried && !lineItem.isCore);
    return modalRef.result;
  }

  clickBuyAgain(): void {
    this.buyAgainSubject.next(this._orderDetails);
  }
}
