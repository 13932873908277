import { OrderTypeString } from "entities/order-type";

export class OrderConfirmationValidator {
  job_000000 = "000000";
  job_000001 = "000001";
  job_000010 = "000010";

  order: OCValidatorParams;
  hasEnding90: boolean = false;

  constructor(params: OCValidatorParams) {
    this.order = params;
    this.hasEnding90 = params.orderItems.some(x => x.partNumber !== "DELIVERY:90" && x.partNumber.endsWith(":90"));
  }

  private _validate(): OCButtonArrangement {
    const distinctJobs = [...new Set(this.order.orderItems.map((x) => x.job))];
    const containsFrontCounterSelfPickItems = this.order.orderItems
      .map((x) => x.deliveryOption)
      .includes("L");
    const containsShoppingBasketItems = this.order.orderItems
      .map((x) => x.deliveryOption)
      .includes("A");
    const distinctDeliveryOptions = [
      ...new Set(this.order.orderItems.map((x) => x.deliveryOption)),
    ];

    const containAdhoc = this.order.orderItems.some(
      (x) => x.deliveryOption === "A"
    );
    const containAdhocOnly = this.order.orderItems.every(
      (x) => x.deliveryOption === "A"
    );
    const containFCSP = this.order.orderItems.some(
      (x) => x.deliveryOption === "L"
    );
    const containFCSPOnly = this.order.orderItems.every(
      (x) => x.deliveryOption === "L"
    );
    const hasNonAdhocAndFCSP = this.order.orderItems.some(
      (x) => x.deliveryOption !== "L" && x.deliveryOption !== "A"
    );
    const containAdhocOrFCSP = this.order.orderItems.every(
      (x) => x.deliveryOption === "L" || x.deliveryOption === "A"
    );
    const containAdhocAndFCSPOnly =
      containAdhoc && containFCSP && !hasNonAdhocAndFCSP;
    const isQuote = this.order.orderType === OrderTypeString.Quote;

    return this.getOCButtonArrangement({
      distinctJobs,
      containsFrontCounterSelfPickItems,
      containsShoppingBasketItems,
      distinctDeliveryOptions,
      containAdhoc,
      containAdhocOnly,
      containFCSP,
      containFCSPOnly,
      hasNonAdhocAndFCSP,
      containAdhocOrFCSP,
      containAdhocAndFCSPOnly,
      isQuote,
    });
  }

  getOCButtonArrangement(data: OCButtonArrangementData): OCButtonArrangement {
    if (data.containAdhocOrFCSP) {
      const fcspOnly = this.getArrangementByContainFCSPOnly(data);
      if (fcspOnly) {
        return fcspOnly;
      }
      const adhoc = this.getArrangementByAdhoc(data);
      if (adhoc) {
        return adhoc;
      }
    }

    if (data.isQuote) {
      return OCButtonArrangement.RTP_H_CB_H;
    }

    const fcsp = this.getArrangementByFCSP(data);
    if (fcsp) {
      return fcsp;
    }

    return this.getArrangementByDistinctJobs(data);
  }

  getArrangementByContainFCSPOnly(data: OCButtonArrangementData) {
    if (data.containFCSPOnly) {
      if (data.isQuote) {
        return OCButtonArrangement.RTP_E_CB_H;
      } else {
        if (
          data.distinctJobs.includes(this.job_000001) &&
          data.distinctJobs.includes(this.job_000010)
        ) {
          return OCButtonArrangement.RTP_E_CB_H;
        }
        if (
          data.distinctJobs.length === 1 &&
          data.distinctJobs.includes(this.job_000010)
        ) {
          return OCButtonArrangement.RTP_H_CB_H;
        }
        return OCButtonArrangement.RTP_E_CB_D;
      }
    }
    return null;
  }

  getArrangementByAdhoc(data: OCButtonArrangementData) {
    if (data.containAdhocOnly) {
      if (data.isQuote) {
        return OCButtonArrangement.RTP_H_CB_H;
      } else {
        return OCButtonArrangement.RTP_H_CB_E;
      }
    }
    if (data.containAdhocAndFCSPOnly) {
      if (data.isQuote) {
        return OCButtonArrangement.RTP_E_CB_H;
      } else {
        return OCButtonArrangement.RTP_E_CB_D;
      }
    }

    if ((data.containAdhoc || data.containFCSP) && data.hasNonAdhocAndFCSP) {
      return OCButtonArrangement.RTP_E_CB_H;
    }
    return null;
  }

  getArrangementByFCSP(data: OCButtonArrangementData) {
    if (
      data.containsFrontCounterSelfPickItems &&
      data.containsShoppingBasketItems &&
      this.order.orderItems
        .filter((x) => x.deliveryOption != "A" && x.deliveryOption != "L")
        .some((x) => x.job === this.job_000001)
    ) {
      return OCButtonArrangement.RTP_E_CB_H;
    }

    if (
      data.containsFrontCounterSelfPickItems &&
      data.containsShoppingBasketItems
    ) {
      return OCButtonArrangement.RTP_E_CB_H;
    }

    if (
      data.containsFrontCounterSelfPickItems &&
      this.order.orderItems.filter(
        (item) => item.job === this.job_000001 && item.deliveryOption != "L"
      ).length >= 1
    ) {
      return OCButtonArrangement.RTP_E_CB_H;
    }

    if (
      data.containsFrontCounterSelfPickItems &&
      data.distinctDeliveryOptions.length === 1
    ) {
      return OCButtonArrangement.RTP_E_CB_H;
    }
    return null;
  }

  getArrangementByDistinctJobs(
    data: OCButtonArrangementData
  ): OCButtonArrangement {
    const oneJobWith000000_quote =
      data.distinctJobs.length === 1 &&
      data.distinctJobs.includes(this.job_000000) &&
      data.isQuote;
    const oneJobWith000000_not_quote =
      data.distinctJobs.length === 1 &&
      data.distinctJobs.includes(this.job_000000) &&
      !data.isQuote;
    const oneJobWithjob_000001 =
      this.order.orderItems.length === 1 &&
      data.distinctJobs.includes(this.job_000001);
    const oneJobWithjob_000010 =
      data.distinctJobs.length === 1 &&
      data.distinctJobs.includes(this.job_000010);
    const jobsWith_000001_000010_000000 =
      data.distinctJobs.includes(this.job_000001) &&
      data.distinctJobs.includes(this.job_000010) &&
      data.distinctJobs.includes(this.job_000000);
    const jobsWith_000001_000010 =
      data.distinctJobs.includes(this.job_000001) &&
      data.distinctJobs.includes(this.job_000010);

    if (oneJobWith000000_quote) {
      return OCButtonArrangement.RTP_H_CB_H;
    } else if (oneJobWith000000_not_quote) {
      return OCButtonArrangement.RTP_H_CB_E;
    }
    if (oneJobWithjob_000001) {
      return OCButtonArrangement.RTP_E_CB_H;
    }
    if (oneJobWithjob_000010) {
      return OCButtonArrangement.RTP_H_CB_H;
    }
    if (jobsWith_000001_000010_000000) {
      return OCButtonArrangement.RTP_E_CB_H;
    }
    if (jobsWith_000001_000010) {
      return OCButtonArrangement.RTP_E_CB_H;
    }
    return this.getArrengementByShoppingBasket(data);
  }

  getArrengementByShoppingBasket(
    data: OCButtonArrangementData
  ): OCButtonArrangement {
    const jobsWith_000001_000000 =
      data.distinctJobs.includes(this.job_000001) &&
      data.distinctJobs.includes(this.job_000000);
    const containsShoppingBasketItems_000010 =
      data.containsShoppingBasketItems &&
      data.distinctJobs.includes(this.job_000010);
    const containsShoppingBasketItems_distinct =
      data.containsShoppingBasketItems && data.distinctJobs.length === 1;

    if (containsShoppingBasketItems_000010) {
      return OCButtonArrangement.RTP_H_CB_H;
    }
    if (containsShoppingBasketItems_distinct) {
      return OCButtonArrangement.RTP_D_CB_H;
    }
    if (jobsWith_000001_000000) {
      return OCButtonArrangement.RTP_E_CB_H;
    }
    return OCButtonArrangement.RTP_H_CB_H;
  }

  validate(): OCValidatorResponse {
    let showCreateBillingButton = true;
    let readyToPickShown = false;
    let createBillingEnabled = false;
    switch (this._validate()) {
      case OCButtonArrangement.RTP_D_CB_E:
        createBillingEnabled = true;
        break;
      case OCButtonArrangement.RTP_D_CB_H:
        showCreateBillingButton = false;
        readyToPickShown = true;
        break;
      case OCButtonArrangement.RTP_E_CB_D:
        readyToPickShown = true;
        break;
      case OCButtonArrangement.RTP_E_CB_E:
        readyToPickShown = true;
        createBillingEnabled = true;
        break;
      case OCButtonArrangement.RTP_E_CB_H:
        readyToPickShown = true;
        showCreateBillingButton = false;
        break;
      case OCButtonArrangement.RTP_H_CB_E:
        createBillingEnabled = true;
        break;
      case OCButtonArrangement.RTP_H_CB_H:
        showCreateBillingButton = false;
        break;
      case OCButtonArrangement.RTP_H_CB_D:
        break;
    }
    return { readyToPickShown, createBillingEnabled, showCreateBillingButton: showCreateBillingButton && !this.hasEnding90 };
  }
}

/* CB = Create Billing Button
 * RTP = Ready To Pick Button
 * E = Enabled
 * D = Disabled
 * H = Hidden
 */
export enum OCButtonArrangement {
  RTP_E_CB_E,
  RTP_D_CB_E, // This Scenario Probably Would Never Happen
  RTP_H_CB_E,

  RTP_E_CB_D,
  RTP_E_CB_H,

  RTP_H_CB_H,
  RTP_D_CB_H,
  RTP_H_CB_D,
}

export interface OCValidatorParams {
  orderItems: {
    job: string;
    deliveryOption: string;
    partNumber: string;
  }[];
  orderType: OrderTypeString;
}

export interface OCValidatorResponse {
  showCreateBillingButton: boolean;
  readyToPickShown: boolean;
  createBillingEnabled: boolean;
}

export interface OCButtonArrangementData {
  distinctJobs: string[];
  containsFrontCounterSelfPickItems: boolean;
  containsShoppingBasketItems: boolean;
  distinctDeliveryOptions: string[];
  containAdhoc: boolean;
  containAdhocOnly: boolean;
  containFCSP: boolean;
  containFCSPOnly: boolean;
  hasNonAdhocAndFCSP: boolean;
  containAdhocOrFCSP: boolean;
  containAdhocAndFCSPOnly: boolean;
  isQuote: boolean;
}
