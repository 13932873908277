import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { Branch } from 'entities/branch';
import { Customer } from 'entities/customer';
import { PartSearch, Refinement } from 'entities/part-search';
import { PartSearchResponse } from 'entities/parts/part-search-response';
import { ToastType } from 'entities/toast-type';
import { Observable, OperatorFunction, Subscription, combineLatest, of } from 'rxjs';
import { filter, map, mapTo, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { SourceLocationType } from 'services/logger.service';
import { LoyaltyService } from 'services/loyalty.service';
import { PartService } from 'services/part.service';
import { ToastService } from 'services/toast.service';
import { TrackerService } from 'services/tracker.service';
import { AppState } from 'store/app-state';
import * as BranchSelectors from 'store/branch/branch.selectors';
import * as CustomerSelectors from 'store/customer/customer.selectors';
import * as LoyaltySelectors from 'store/loyalty/loyalty.selectors';

interface QueryState {
  term: string;
  partId: string;
  isExpandedSearch: boolean;
  facets: Refinement[];
  pageNumber: number;
}

interface PartBaseComponentState {
  query: QueryState;
  result: PartSearchResponse;
  loading: boolean;
  dismissedCreatePart: boolean;
}

@Injectable()
export class PartBaseComponentStore extends ComponentStore<PartBaseComponentState> {
  public readonly pageSize = 20;

  constructor(
    private store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private partService: PartService,
    private toastService: ToastService,
    private trackerService: TrackerService,
    private loyaltyService: LoyaltyService
  ) {
    super({
      query: {
        term: null,
        partId: null,
        isExpandedSearch: false,
        facets: [],
        pageNumber: 1
      },
      result: null,
      loading: false,
      dismissedCreatePart: false
    });
  }

  public readonly init = this.effect<void, Observable<void>, void, (observableOrValue: void | Observable<void>) => Subscription>((source$: Observable<void>) =>
    source$
      .pipe(
        switchMap(() => this.activatedRoute.queryParams),
        map((queryParams) => ({
          term: queryParams['searchTerm'] || '',
          partId: queryParams['partId'] || '',
          isExpandedSearch: queryParams['isExpandedSearch'] === 'true',
          pageNumber: Number(queryParams['page']) || 1
        })),
        filter((queryParams) => {
          const queryState = this.get((state) => state.query);
          return queryState.term !== queryParams.term
            || queryState.partId !== queryParams.partId
            || queryState.isExpandedSearch !== queryParams.isExpandedSearch
            || queryState.pageNumber !== queryParams.pageNumber;
        }),
        map((queryParams): QueryState => ({
          ...queryParams,
          facets: []
        })),
        this.searchParts()
      )
  );

  public readonly updateFacets = this.effect((facets$: Observable<Refinement[]>) =>
    facets$
      .pipe(
        tap((newFacets) => {
          this.patchState({
            query: {
              ...this.get((state) => state.query),
              facets: newFacets
            }
          });
        }),
        map((facets) => ({
          ...this.get((state) => state.query),
          facets,
          pageNumber: 1
        })),
        this.searchParts()
      )
  );

  public readonly clearFacets = this.updater((state) => ({
    ...state,
    query: {
      ...state.query,
      facets: []
    },
  }));

  public readonly dismissCreatePart = this.updater((state) => ({
    ...state,
    dismissedCreatePart: true
  }));

  private readonly query$ = this.select((state) => state.query);
  public readonly queryTerm$ = this.select(this.query$, (query) => query.term);
  public readonly pageNumber$ = this.select(this.query$, (query) => query.pageNumber);
  public readonly loading$ = this.select((state) => state.loading);
  public readonly result$ = this.select((state) => state.result);
  public readonly count$ = this.select(this.result$, (result) => result?.totalParts ?? 0);
  public readonly showCreatePart$ = this.select((state) => !state.loading && !state.result?.totalParts && !state.dismissedCreatePart);
  public readonly facets$ = this.select(this.query$, (query) => query.facets);

  private searchParts(): OperatorFunction<QueryState, void> {
    return (query$: Observable<QueryState>) =>
    // basing the observable off both the query$ and loyalty account, allows the search to fire even when a loyalty account is changed
    combineLatest([this.store.select(LoyaltySelectors.loyaltyAccount), query$])
      .pipe(
        map(([_, newQuery]) => newQuery),
        tap((newQuery) => {
          this.patchState({
            query: {
              ...this.get((state) => state.query),
              term: newQuery.term,
              partId: newQuery.partId,
              isExpandedSearch: newQuery.isExpandedSearch,
              pageNumber: newQuery.pageNumber
            }
          });
        }),
        switchMap((query) =>
          combineLatest([
            this.store.select(BranchSelectors.selectedBranch),
            this.store.select(CustomerSelectors.selectedCustomer)
          ])
            .pipe(
              filter(([branch, customer]) => Boolean(branch) && Boolean(customer)),
              map(([branch, customer]): [QueryState, Branch, Customer] => [query, branch, customer])
            )
        ),
        filter(([query, branch, customer]) => branch.code !== null && customer.customerNumber !== null),
        tap(() => {
          this.patchState({
            loading: true,
            dismissedCreatePart: false
          });
        }),
        map(([query, branch, customer]): PartSearch => ({
          branchCode: branch.code,
          customerNumber: customer.customerNumber,
          partSearchTerm: query.term,
          partId: query.partId,
          isExpandedSearch: query.isExpandedSearch,
          isCountCheck: false,
          refinements: this.get((state) => state.query).facets,
          pageNumber: query.pageNumber,
          pageSize: this.pageSize
        })),
        switchMap((partSearch) => this.partService.searchParts(partSearch)
          .pipe(
            tap((response) => {
              if (response.ErrorType && response.ErrorType !== 200) {
                throw new Error(response);
              }
            }),
            withLatestFrom(
              this.store.select(LoyaltySelectors.loyaltyAccount),
              this.store.select(BranchSelectors.selectedBranch)),
            switchMap(([result, loyaltyAccount, branch]) => {
              if (loyaltyAccount) {
                const partSearchResponse: PartSearchResponse = result;
                return this.loyaltyService.getDiscounts({
                  memberId: loyaltyAccount.loyaltyAccountIdentifier,
                  partNumbers: result.parts.map(x => x.rushPartNumber),
                  branchCode: branch.code,
                  loyaltyProgram: loyaltyAccount.loyaltyProgram
                }).pipe(
                   map((discounts) => {
                    partSearchResponse.parts = partSearchResponse.parts.map(part => {
                      const discount: any = discounts[part.rushPartNumber.split(":")[0]];
                      if (discount) {
                        const loyaltyProgram = loyaltyAccount.loyaltyProgram;
                        part.sapCoupons = [
                          ...part.sapCoupons,
                          {
                            amount: discount.amount,
                            description: discount.code + " " + loyaltyProgram.charAt(0).toUpperCase() + loyaltyProgram.slice(1),
                            type: 'part'
                          }
                        ];
                      }
                      return part;
                    });
                    return partSearchResponse;
                  })
                );
              }
              return of(result);
            }),
            tapResponse(
              (result: PartSearchResponse) => {
                const source = SourceLocationType[this.activatedRoute.snapshot.queryParams['src']];
                this.trackerService.trackPartSearch(result.parts, partSearch.partSearchTerm, source);

                if (!result || result.totalParts <= 0) {
                  this.toastService.showToast('Part not found.', ToastType.Error);
                }
                if (result && result.totalParts > 1 && result.isExpandedSearch) {
                  this.toastService.showToast('Your results contain parts from an expanded industry search', ToastType.Success);
                }

                this.setState((state) => ({
                  ...state,
                  query: {
                    ...state.query,
                    isExpandedSearch: result.isExpandedSearch,
                    pageNumber: partSearch.pageNumber
                  },
                  result,
                  loading: false,
                  dismissedCreatePart: false
                }));
                this.router.navigate(['/parts'], {
                  queryParams: {
                    isExpandedSearch: result.isExpandedSearch,
                    page: partSearch.pageNumber
                  },
                  queryParamsHandling: "merge",
                  onSameUrlNavigation: "ignore"
                });
              },
              (error) => {
                this.toastService.errorMessage('PartBaseComponent', 'getParts', 'getParts', null);
                this.patchState({ loading: false, dismissedCreatePart: false });
              }),

          )
        ),
        mapTo(void 0)
      );
  }

}
