import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, debounceTime, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { ConfigurationService } from "services/configuration.service";
import { AppState } from "store/app-state";
import * as backCounterReducer from "store/back-counter/back-counter.reducer";
import * as basketReducer from 'store/basket/basket.reducer';
import * as BranchActions from 'store/branch/branch.actions';
import * as branchReducer from "store/branch/branch.reducer";
import * as CartActions from 'store/cart/cart.actions';
import * as selectedCartReducer from 'store/cart/cart.reducer';
import * as ConfigurationActions from 'store/configuration/configuration.actions';
import * as configurationReducer from 'store/configuration/configuration.reducer';
import * as CustomerActions from 'store/customer/customer.actions';
import * as customerReducer from 'store/customer/customer.reducer';
import * as FeatureFlagActions from 'store/feature-flags/feature-flags.actions';
import * as featureFlagsReducer from 'store/feature-flags/feature-flags.reducer';
import * as featuredPartsReducer from 'store/featured-parts/featured-parts.reducer';
import * as loyaltyProfileReducer from 'store/loyalty/loyalty.reducer';
import * as myCartReducer from 'store/my-cart/my-cart.reducer';
import * as myDashboardReducer from 'store/my-dashboard/my-dashboard.reducer';
import * as orderConfirmationReducer from 'store/order-confirmation/order-confirmation.reducer';
import * as provinceReducer from 'store/province/province.reducer';
import * as verifiedPricesReducer from 'store/verified-prices/verified-prices.reducer';
import * as LineItemNotesModalComponentActions from '../../_modals/line-item-notes-modal/line-item-notes-modal.component.actions';

@Injectable()
export class ConfigurationEffects {

  getConfiguration$ = createEffect(() => this.action$.pipe(
    ofType(ConfigurationActions.getConfiguration),
    switchMap(
      () => this.configurationService.getConfiguration().pipe(
        map((configuration) => ConfigurationActions.getConfigurationSuccess({ configuration })),
        catchError(error => of(ConfigurationActions.getConfigurationFailed({ error })))
      )
    )
  ));

  initializeApplicationAfterLoadingCache$ = createEffect(() => this.action$.pipe(
    ofType(ConfigurationActions.loadCacheSuccess),
    tap(() => {
      this.store.dispatch(FeatureFlagActions.getFeatureFlags());
      this.store.dispatch(ConfigurationActions.getConfiguration());
      this.store.dispatch(CartActions.getOpenCarts());
    })
  ), {dispatch: false});

  getBranchesAfterConfiguration$ = createEffect(() => this.action$.pipe(
    ofType(ConfigurationActions.getConfigurationSuccess),
    map(() => BranchActions.getBranches())
  ));

  cacheOrLoadAppstate$ = createEffect(() => this.action$.pipe(
    ofType(
      CartActions.getPartBinLocationsSuccess,
      CustomerActions.findCustomersSuccess,
      CartActions.setDeliveryFeeSuccess,
      CartActions.updateShippingRateSuccess,
      ConfigurationActions.loadCache,
      CartActions.getCartDataSuccess,
      CartActions.selectOpenCartSuccess,
      CartActions.addItemToCartSuccess,
      CartActions.updateHotFlagOnCartItemSuccess,
      LineItemNotesModalComponentActions.addedItemNote,
      LineItemNotesModalComponentActions.removedItemNote,
      CartActions.removeItemsFromCartSuccess,
      CartActions.addFavoritePartsToCartSuccess,
      CartActions.setDeliverySuccess,
      CartActions.updateShipToSuccess,
      CartActions.deleteCartSuccess,
      CartActions.updateHotFlagOnCartItemSuccess,
      CartActions.updateQuoteSuccess,
      CartActions.submitOrderSuccess,
      CartActions.updateTransactionTypeSuccess,
      CartActions.updateCartSuccess,
      CartActions.updateShippingRateSuccess,
      CartActions.setDeliveryItems,
      CustomerActions.setSelectedPaymentMethodSuccess,
      CustomerActions.clearCustomer,
      BranchActions.selectBranch),
    debounceTime(500),
    withLatestFrom(this.store),
    tap(([{type}, storeAppstate]) => {
      if(type === "[Configuration] Load Cache"){
        const appstate: AppState = JSON.parse(localStorage.getItem("appstate"))?.storeAppstate;
        const timeStamp: number = JSON.parse(localStorage.getItem("appstate"))?.timeStoredMiliSeconds;
        const diff = Date.now() - timeStamp;
        const TWELVE_HOURS_IN_MILIS = 43200000;

        if(appstate && diff < TWELVE_HOURS_IN_MILIS){
          this.store.dispatch(ConfigurationActions.loadCacheSuccess({appstate}));
        } else {
          const initialAppstate: AppState = this.getInitialAppState();
          this.store.dispatch(ConfigurationActions.loadCacheSuccess({appstate: initialAppstate}));
        }
      } else {
        localStorage.setItem("appstate", JSON.stringify({storeAppstate, timeStoredMiliSeconds: Date.now()}));
      }

    })
  ), {dispatch: false});

  getInitialAppState(): AppState{
    const basket = basketReducer.initialState;
    const branch = branchReducer.initialState;
    const configuration = configurationReducer.initialState;
    const customer = customerReducer.initialState;
    const featuredParts = featuredPartsReducer.initialState;
    const featureFlags = featureFlagsReducer.initialState;
    const loyaltyProfile = loyaltyProfileReducer.initialState;
    const myCart = myCartReducer.initialState;
    const myDashboard = myDashboardReducer.initialState;
    const orderConfirmation = orderConfirmationReducer.initialState;
    const province = provinceReducer.initialState;
    const selectedCart = selectedCartReducer.initialState;
    const verifiedPrices = verifiedPricesReducer.initialState;
    const backCounter = backCounterReducer.initialState;

    const initialState: AppState = {
      basket,
      branch,
      configuration,
      customer,
      featuredParts,
      featureFlags,
      loyaltyProfile,
      myCart,
      myDashboard,
      orderConfirmation,
      province,
      selectedCart,
      verifiedPrices,
      backCounter
    };
    return initialState;
  }

  constructor(
    private action$: Actions,
    private configurationService: ConfigurationService,
    private store: Store<AppState>
  ) {}
}

