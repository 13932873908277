import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from 'services/cart.service';
import { SourceLocationType } from 'services/logger.service';
import { ToastService } from 'services/toast.service';

@Component({
  selector: 'close-reason-modal',
  templateUrl: './close-reason-modal.component.html'
})
export class CloseReasonModalComponent implements OnInit {
  subscription: any;

  @Input() reasonFor: SourceLocationType;
  @Input() cartItemData: any;
  public closeReasonId: any;
  public closeReasons: any;

  get headerTitleText(): string {
    switch (this.reasonFor) {
      case SourceLocationType.CartItemCloseReason:
        return 'Reason for deleting line item(s)';
      case SourceLocationType.CartCloseReason:
        return 'Reason for closing quote';
      default:
        return '';
    }
  }

  constructor(
    private toast: ToastService,
    private cartService: CartService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.getCloseReasons();
  }

  getCloseReasons(): void {
    this.closeReasons = null;
    this.cartService.getCloseQuoteReasons()
      .then(c => {
        if (c.ErrorType != undefined && c.ErrorType != null && c.ErrorType != 200) {
          this.toast.errorMessage('CloseReasonModalComponent', 'getCloseReasons', 'getCloseReasons', c);
        } else {
          this.closeReasons = c;
        }
      })
      .catch(() => { });
  }

  submit(): void {
    let data = {
      cartItemData: this.cartItemData,
      closeReasonId: this.closeReasonId
    };
    this.activeModal.close(data);
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }
}
