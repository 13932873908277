export type ColumnName =
  | "partNumber"
  | "item"
  | "hotFlag"
  | "qty"
  | "totalCoupon"
  | "finalPrice"
  | "total"
  | "jobNumber";

export const createOrderItemColumnClass = (
  columnName: ColumnName,
  isInternalUser: boolean = false,
  showCouponColumn: boolean = false,
  showHotFlagColumn: boolean = false
) => {
  let resp = "";
  switch (columnName) {
    case "partNumber":
      resp = getColByPartNumber(showHotFlagColumn, isInternalUser, showCouponColumn);
      break;
    case "item":
      resp = getColByItem(showHotFlagColumn, isInternalUser, showCouponColumn);
      break;
    case "hotFlag":
      resp = getColByHotFlag(showHotFlagColumn);
      break;
    case "qty":
      resp = getColByQty(showHotFlagColumn, isInternalUser, showCouponColumn);
      break;
    case "totalCoupon":
      resp = getColByTotalCoupon(showCouponColumn);
      break;
    case "finalPrice":
      resp = getColByFinalPrice(showCouponColumn);
      break;
    case "total":
      resp = getColByTotal(showHotFlagColumn, isInternalUser, showCouponColumn);
      break;
    case "jobNumber":
      resp = getColByJobNumber(isInternalUser);
      break;
    default:
      break;
    }
  return resp;
};

const getColByPartNumber = (showHotFlagColumn: boolean, isInternalUser: boolean, showCouponColumn: boolean): string => {
  let resp = "";
  if (
    (showHotFlagColumn && isInternalUser) ||
    (showHotFlagColumn && isInternalUser && showCouponColumn)
  ) {
    resp = "col-2";
  } else {
    resp = "col-3";
  }
  return resp;
}

const getColByItem = (showHotFlagColumn: boolean, isInternalUser: boolean, showCouponColumn: boolean): string => {
  let resp = "";
  if (showCouponColumn && showHotFlagColumn && isInternalUser) {
    resp = "col-2";
  } else {
    resp = "col-3";
  }
  return resp;
}

const getColByHotFlag = (showHotFlagColumn: boolean): string => {
  let resp = "";
  if (showHotFlagColumn) {
    resp = "col-2";
  }
  return resp;
}

const getColByQty = (showHotFlagColumn: boolean, isInternalUser: boolean, showCouponColumn: boolean): string => {
  let resp = "";
  if (!showCouponColumn && !showHotFlagColumn && !isInternalUser) {
    resp = "col-3";
  } else if (
    (!showCouponColumn && showHotFlagColumn && !isInternalUser) ||
    (!showCouponColumn && !showHotFlagColumn && isInternalUser)
  ) {
    resp = "col-2";
  }
  else {
    resp = "col-1";
  }
  return resp;
}

const getColByTotalCoupon = (showCouponColumn: boolean): string => {
  let resp = "";
  if (showCouponColumn) {
    resp = "col-1";
  }
  return resp;
}

const getColByFinalPrice = (showCouponColumn: boolean): string => {
  let resp = "";
  if (showCouponColumn) {
    resp = "col-2";
  }
  return resp;
}

const getColByTotal = (showHotFlagColumn: boolean, isInternalUser: boolean, showCouponColumn: boolean): string => {
  let resp = "";
  if (!showCouponColumn && !showHotFlagColumn && !isInternalUser) {
    resp = "col-3";
  }
  else {
    resp = "col-2";
  }
  return resp;
}

const getColByJobNumber = (isInternalUser: boolean): string => {
  let resp = "";
  if (isInternalUser) {
    resp = "col-2";
  }
  return resp;
}

