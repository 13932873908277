import { ChangeDetectionStrategy, Component, Inject, OnInit, Self } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationExtras, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LoyaltyDiscountsStore } from 'app/_components/loyalty-discounts/loyalty-discounts.store';
import { APPLICATION_NAME } from 'app/app.constants';
import { Branch } from 'entities/branch';
import { Part } from 'entities/part';
import { Refinement } from 'entities/part-search';
import { PartSearchResponse } from 'entities/parts/part-search-response';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthorizationService } from 'services/authorization.service';
import { AutocompleteService } from 'services/auto-complete.service';
import { CommonDataService } from 'services/common-data.service';
import { SourceLocationType } from 'services/logger.service';
import { AppState } from 'store/app-state';
import * as CartActions from 'store/cart/cart.actions';
import { PartBaseComponentStore } from './part-base.component.store';

@Component({
  selector: 'part-base',
  templateUrl: './part-base.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [PartBaseComponentStore, LoyaltyDiscountsStore]
})
export class PartBaseComponent implements OnInit {
  pageSize: number = this.componentStore.pageSize;
  partSearchTerm$: Observable<string> = this.componentStore.queryTerm$;
  currentPage$: Observable<number> = this.componentStore.pageNumber$;
  parts$: Observable<PartSearchResponse> = this.componentStore.result$;
  partCount$: Observable<number> = this.componentStore.count$;
  loading$: Observable<boolean> = this.componentStore.loading$;
  showCreatePart$: Observable<boolean> = this.componentStore.showCreatePart$;
  facets$: Observable<Refinement[]> = this.componentStore.facets$;
  clearFilters$: Observable<void> = this.autoCompleteService.clearFilters$.pipe(tap(() => this.clearFacets()));

  showCreatePart: boolean = false;
  showCreatePartLoader: boolean = false;

  public branch$: Observable<Branch> = this.commonDataService.branch$;

  constructor(
    private store: Store<AppState>,
    private componentStore: PartBaseComponentStore,
    @Self() private loyaltyDiscountsStore: LoyaltyDiscountsStore,
    private commonDataService: CommonDataService,
    private router: Router,
    public authorizationService: AuthorizationService,
    private autoCompleteService: AutocompleteService,
    title: Title,
    @Inject(APPLICATION_NAME) appName: string
  ) {
    title.setTitle(`${appName} - Parts`);
    this.store.dispatch(CartActions.getCartDataForBranchAndCustomer());
  }

  ngOnInit() {
    this.componentStore.init();
    this.loyaltyDiscountsStore.getDiscounts(this.componentStore.result$.pipe(map((result: PartSearchResponse) => result?.parts ?? [])));
  }

  updatePage(page: number) {
    this.router.navigate(['/parts'], {
      queryParams: {
        page: page
      },
      queryParamsHandling: "merge",
      onSameUrlNavigation: "ignore"
    } as NavigationExtras);
  }

  updateFacets(facets: Refinement[]) {
    this.componentStore.updateFacets(facets);
  }

  goToPart(part: Part | string) {
    const partQueryParams = typeof part === 'string'
      ? { searchTerm: part }
      : { searchTerm: part.rushPartNumber, partId: part.partId };

    return this.router.navigate(['/parts'], {
      queryParams: {
        ...partQueryParams,
        src: SourceLocationType.PartListResult
      }
    } as NavigationExtras);
  }

  dismissCreatePart(): void {
    this.componentStore.dismissCreatePart();
  }

  clearFacets(): void {
    this.componentStore.clearFacets();
  }
}
