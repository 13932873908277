import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { DEFAULT_IMAGE_URL, IMAGE_BASE_URL } from 'app/app.constants';
import { DisplayType } from 'entities/back-counter-tool';
import { Branch } from 'entities/branch';
import { Part } from 'entities/part';
import { Refinement } from 'entities/part-search';
import { Bucket, FacetResponse, PartSearchResponse } from 'entities/parts/part-search-response';
import { ToastType } from 'entities/toast-type';
import { getImageHelper } from 'helpers/get-image';
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest } from 'rxjs';
import { map, scan, tap } from 'rxjs/operators';
import { CommonDataService } from 'services/common-data.service';
import { FavoritePartsService } from 'services/favorite-parts.service';
import { LoaderService } from 'services/loader.service';
import { ToastService } from 'services/toast.service';
import { AppState } from 'store/app-state';
import * as BackCounterRequestActions from 'store/back-counter/back-counter.actions';
import * as CartActions from 'store/cart/cart.actions';
import * as CartSelectors from 'store/cart/cart.selectors';
import * as ConfigurationSelectors from 'store/configuration/configuration.selectors';



@Component({
  selector: "part-list",
  templateUrl: "./part-list.component.html",
  styleUrls: ['./part-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PartListComponent implements AfterViewInit {
  public partsDataSubject: Subject<PartSearchResponse> = new ReplaySubject(1);
  public partsData$: Observable<PartSearchResponse> = this.partsDataSubject.asObservable().pipe(
    map((partResponse: PartSearchResponse) => {
      this.loaderService.loading = false;
      const parts = partResponse.parts.map(x => ({...x, coreOption: 'NOCORER'}));
      return {...partResponse, parts};
    })
  );
  isQuote$: Observable<boolean> = this.store.select(CartSelectors.selectIsQuote);
  initialPartResponse = { totalParts: 0, parts: [] } as PartSearchResponse;
  allParts$ = this.partsData$.pipe(
    scan((acc, current) => {
      if(current.parts.length > 0){
        return ({
          totalParts: acc.totalParts + current.totalParts,
          parts: [...acc.parts, ...current.parts.filter(x => x.quantityAvailable > 0)],
        });
      } else {
        return this.initialPartResponse;
      }
    }, this.initialPartResponse)
  );

  @ViewChild('partList_div_PartsData') inStockPartList: ElementRef;
  @Input() displayType: DisplayType;
  @Input() set partsData(val: PartSearchResponse) {
    this.partsDataSubject.next(val);
  }
  _partSearchTerm = null;
  @Input() set partSearchTerm(val: string){
    this.inStockOnly = false;
    this._partSearchTerm = val;
    this.resetInStockSearch();
  }
  get partSearchTerm(){
    return this._partSearchTerm;
  }
  @Input() currentPage: number;
  @Input() pageSize: number;
  _branch: Branch = null;
  @Input() set branch(val: Branch){
    this.inStockOnly = false;
    this._branch = val;
    this.resetInStockSearch();
  };
  get branch(){
    return this._branch;
  }
  private requireExtended: boolean = false;
  @Input("requireExtended") set requireExtendedInput(value: any) {
    this.requireExtended = value === "" || value;
  }
  @Input("loading") loading: boolean = false;
  @Input() highlightPart: Part;
  @Input() facets: Refinement[] = [];
  @Output() updatePage: EventEmitter<number> = new EventEmitter();
  @Output() updateFacets: EventEmitter<Refinement[]> = new EventEmitter();
  @Output("selectPart") selectPartEmitter: EventEmitter<Part | string> =
    new EventEmitter();

  private manufacturerFilterSubject: Subject<string> = new BehaviorSubject(
    null
  );
  public manufacturerBuckets$: Observable<Bucket[]> = combineLatest(
    this.partsData$,
    this.manufacturerFilterSubject
  ).pipe(
    map(([partsData, manufacturerFilter]: [PartSearchResponse, string]) =>
      manufacturerFilter
        ? partsData.facets.manufacturer.buckets.filter((bucket: Bucket) =>
          bucket.term.toLowerCase().includes(manufacturerFilter.toLowerCase())
        )
        : partsData.facets?.manufacturer?.buckets
    )
  );

  public totalParts$: Observable<number> = this.partsData$.pipe(
    map((response: PartSearchResponse): number => response.totalParts)
  );

  public selectedFacets: Refinement[] = [];

  _inStockOnly: boolean = false;
  set inStockOnly(val: boolean){
    this._inStockOnly = val;
  }
  get inStockOnly(){
    return this._inStockOnly;
  }

  isInternal$ = this.store.select(ConfigurationSelectors.isInternal);


  constructor(
    private store: Store<AppState>,
    private toastService: ToastService,
    private favoritePartService: FavoritePartsService,
    public commonDataService: CommonDataService,
    private changeDetectorRef: ChangeDetectorRef,
    private modalService: NgbModal,
    @Inject(IMAGE_BASE_URL) public imageBaseUrl: string,
    @Inject(DEFAULT_IMAGE_URL) public defaultImage: string,
    private loaderService: LoaderService
  ) { }

  ngAfterViewInit(): void {
    this.selectedFacets = this.facets;
  }

  onManufacturerFilterChange(manufacturerFilter: string) {
    this.manufacturerFilterSubject.next(manufacturerFilter);
  }

  onFacetChange(arr: FacetResponse) {
    const selectedManufacturers = {
      field: arr.manufacturer.field,
      terms: arr.manufacturer.buckets
        .filter((b) => b.selected)
        .map((f) => f.term),
    };
    const selectedCategories = {
      field: arr.category.field,
      terms: arr.category.buckets.filter((b) => b.selected).map((f) => f.term),
    };
    const selectedFacets: Refinement[] = [];
    if (selectedManufacturers.terms.length) {
      selectedFacets.push(selectedManufacturers);
    }
    if (selectedCategories.terms.length) {
      selectedFacets.push(selectedCategories);
    }
    this.selectedFacets = selectedFacets;
    this.updateFacets.emit(selectedFacets);
    this.partsDataSubject.next(this.initialPartResponse);
    if(this.inStockOnly){
      this.setPage(1);
    }
  }

  addToRequest(part: Part){
    this.selectPartEmitter.next({...part, coreOption: part.corePart === '' || part.corePart === null || part.corePart === undefined ? "" :part.coreOption });
    this.store.dispatch(BackCounterRequestActions.partAddedToRequest({part}));
  }

  copyPartNumberToClipBoard(part: Part) {
    const contentToCopy = part.isRushPart ? part.rushPartNumber : part.number;
    window.navigator['clipboard'].writeText(contentToCopy);
    this.toastService.showToast(
      `${contentToCopy} copied to clipboard.`,
      ToastType.Success
    );
  }

  openNationalInventory(part: Part) {
    this.store.dispatch(CartActions.showNationalInventoryModal({ part }));
  }

  addToFavoriteParts(part: Part) {
    this.favoritePartService
      .addPartToFavorites(part)
      .pipe(tap(() => this.changeDetectorRef.markForCheck()))
      .subscribe();
  }

  setPage(page: number) {
    this.updatePage.emit(page);
  }

  removeFavoritePart(part: Part) {
    this.favoritePartService
      .removePartFromFavorites(part)
      .pipe(tap(() => this.changeDetectorRef.markForCheck()))
      .subscribe();
  }

  selectPart(part: Part) {
    if (this.requireExtended && !part.isExtendedToBranch) {
      this.toastService.showToast(
        "Part is not available on this branch",
        ToastType.Warning
      );
      return;
    }

    this.selectPartEmitter.emit(part);
  }

  onExtendPart(extendedPartNumber: string) {
    this.selectPartEmitter.emit(extendedPartNumber);
  }

  getImage(image: string, imageBaseUrl: string, imageSize: string): string {
    return getImageHelper(image, imageBaseUrl, imageSize)
  }

  changeQuantity(value: number, part: Part){
    part.quantity = value;
  }

  selectCoreReturn(event, part: Part){
    part.coreOption = event;
  }
  continueBrowsingInStock(page: number){
    if(!this.loading){
      this.setPage(page + 1);
    }
  }
  onScroll(event: Event){
    const target = event.target as HTMLElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;
    const bottomDetectionOffSet = 100;

    if (scrollTop + clientHeight >= (scrollHeight-bottomDetectionOffSet) && !this.loading) { // End of scroll area
      this.continueBrowsingInStock(this.currentPage);
    }
  }

  onInStockChange(){
    if(!this.inStockOnly){
      this.setPage(1);
    }
  }

  resetInStockSearch(){
    if(this.inStockOnly){
      this.setPage(1);
      this.partsDataSubject.next(this.initialPartResponse);
    }
  }

  scrollToTop(){
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const partListElement = document.querySelector('#product-list-container');
    if (partListElement) {
      partListElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  showLoader(){
    this.loaderService.loading = true;
  }

}
