import { Component, Input } from '@angular/core';
import { Part } from 'entities/part';

@Component({
  selector: 'part-card-description',
  templateUrl: './part-card-description.component.html'
})
export class PartCardDescriptionComponent {
  @Input() idSuffix: string = "";
  @Input() part: Part;

  constructor() { }

}
