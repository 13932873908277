import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from "@ngrx/store";
import { LoggerService } from 'services/logger.service';
import { AppState } from 'store/app-state';
import * as BranchSelectors from "store/branch/branch.selectors";

@Component({
  selector: 'customer-review-modal',
  templateUrl: './customer-review-form.component.html',
  styleUrls: ['./customer-review-form.component.scss']
})
export class CustomerReviewFormComponent {

  public isVerify = false;
  @Input() paymentDue = 0;

  public crfDocumentSrc: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl("about:blank");
  currencyCode$ = this.store.select(BranchSelectors.selectCurrencyCode);
  constructor( public store: Store<AppState>, private sanitizer: DomSanitizer, private activeModal: NgbActiveModal, private loggerService: LoggerService ) { }

  updateUrl(resp: any) {
      try {
        this.crfDocumentSrc = this.sanitizer.bypassSecurityTrustResourceUrl(resp);
      } catch(e) {
        this.loggerService.verbose("url: sanitizer cannot handle => " + resp );
      }
    }

    verify() {
      this.isVerify = true;
      let modalDlg = document.getElementsByClassName('modal-content')[0];
      (modalDlg as HTMLElement).style.width = '400px';
      (modalDlg as HTMLElement).style.marginLeft = '200px';
    }

    public closeModal(): void {
      let modalDlg = document.getElementsByClassName('modal-content')[0];
      (modalDlg as HTMLElement).style.width = '800px';
      (modalDlg as HTMLElement).style.marginLeft = '0px';
      this.isVerify = false;
    }

    public cancel(): void {
      this.activeModal.dismiss();
    }

    public approve(): void {
      this.activeModal.close({success: Boolean});
    }
}
